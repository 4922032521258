import { useForm } from 'react-hook-form';
import InputBox from '../common/form/InputBox';
import { Button } from '../common/Button';
import { useChangePassword } from '@/api/others/useChangePassword';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePasswordValidationSchema } from '@/lib/validator';
const ChangePasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmedPassword: '',
    },
    resolver: yupResolver(updatePasswordValidationSchema),
  });
  const { mutate } = useChangePassword();
  const changePassword = (passwordData: any) => {
    console.log();

    mutate(passwordData, {
      onSuccess(message) {
        if (!message) {
          return toast.success('updated successfully');
        }
        return toast.error(message);
      },
    });
  };
  return (
    <form className="my-5 flex w-[400px] flex-col  gap-3" onSubmit={handleSubmit(changePassword)}>
      <InputBox
        register={register}
        name="oldPassword"
        placeholder="Enter your current password"
        type="password"
        error={errors.oldPassword?.message}
        id="oldPassword"
      />
      <InputBox
        register={register}
        error={errors.password?.message}
        name="password"
        placeholder="Enter your new password"
        type="password"
        id="password"
      />
      <InputBox
        register={register}
        error={errors.confirmedPassword?.message}
        name="confirmedPassword"
        placeholder="Enter your new confirm password"
        type="password"
        id="confirmPassword"
      />
      <div className="flex w-full items-center justify-between gap-3 ">
        <Button variant="transparent" className="w-full gap-2 rounded-xl border-2">
          Save Changes
        </Button>
        <Button
          type="reset"
          variant="transparent"
          className="w-[200px] rounded-xl border-2 border-red-500 text-red-600"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
