import { useGetAllCategory } from '@/api/category/useGetAllCategory';
import { Button, buttonVariants } from '@/components/common/Button';
import CustomDialog from '@/components/common/CustomDialog';
import MainCategoryCard from '@/components/quick-learn/main-category.card';
import { cn } from '@/utils';
import { InfoIcon, X } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import UpdateCategory from './EditCategory';
import CreateCategory from './CreateCategory';
import { DeleteButton, EditButton, InfoButton } from '@/components/common/IconButtons/IconButtons';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { setTag } from '@/store/slices/tags.slice';
import { useDeleteCategory } from '@/api/category/useDeleteCategory';
import { toast } from 'react-toastify';
import ConfirmBox from '@/components/common/form/ConfirmBox';

const Category = () => {
  const { data: main_tags } = useGetAllCategory({ search: '' });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [confirm, setConfirm] = useState(false);
  const { mutate } = useDeleteCategory();
  const tag = useAppSelector((state) => state.tagSlice);

  console.log(main_tags);
  const deleteHandler = (tag: { id: string; name: string, thumbnailUrl: string }) => {
    dispatch(setTag(tag));
    setConfirm(true);
  };
  const handleConfirm = () => {
    const id = tag.id;
    mutate(id, {
      onError() {
        toast.error('error deleting category');
      },
      onSuccess() {
        toast.success('delete category successfully');
        setConfirm(false);
      },
    });
  };
  const handleEdit = (tag: { id: string; name: string, thumbnailUrl: string }) => {
    setIsDialogOpen(true);
    setIsEditMode(true);
    dispatch(setTag(tag));
  };
  return (
    <div className=" m-5  rounded-xl bg-white p-5">
      <ConfirmBox
        description="Are you sure to delete?"
        onCloseHandle={() => {
          setConfirm(false);
        }}
        onConfirm={handleConfirm}
        open={confirm}
      />
      <div className=" py-5">
        <Button
          onClick={() => setIsDialogOpen(true)}
          className={cn(buttonVariants({ variant: 'transparent' }), 'rounded-md py-1.5 text-sm')}
        >
          <X className=" mr-2 rotate-45 text-sm" /> Create Main Category
        </Button>
      </div>
      <div className="flex flex-wrap gap-5">
        {main_tags?.data.map((tag) => {
          return (
            <Link to={`/home/category/${tag.id}`} key={tag.id}>
              <MainCategoryCard handleDelete={deleteHandler} handleEdit={handleEdit} tag={tag} />
            </Link>
          );
        })}
        {/* <table className="ext-gray-500 table-color w-full rounded-lg text-left text-sm ">
          <thead className=" bg-[#97B3CE] text-xs uppercase">
            <tr>
              <th scope="col" className="px-6 py-3">
                No.
              </th>
              <th scope="col" className="px-6 py-3">
                Category Name
              </th>
              <th scope="col" className="px-6 py-3">
                Thumbnail
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {main_tags?.data.map((tag) => {
              return (
                <tr className="border-t odd:bg-[#F1F3F6] even:bg-[#E5EFF9]" key={tag.id}>
                  <th scope="row" className="whitespace-nowrap px-6 py-2 font-medium ">
                    {tag.id}
                  </th>
                  <th scope="row" className="whitespace-nowrap px-6 py-2 font-medium ">
                    {tag?.name}
                  </th>
                  <th scope="row" className="whitespace-nowrap px-6 py-2 font-medium ">
                    <img src={tag?.thumbnailUrl} alt="sdfasdf" className="h-10 w-10" />
                  </th>

                  <td className="flex gap-4 px-6 py-4">
                    <button onClick={() => deleteHandler(tag)}>
                      <DeleteButton />
                    </button>
                    <button onClick={() => handleEdit(tag)}>
                      <EditButton disabled={false} />
                    </button>
                    <Link to={`/home/category/${tag.id}`}>
                      <InfoButton />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}
      </div>
      <CustomDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <div className=" w-[350px] ">
          {isEditMode ? (
            <UpdateCategory setIsDialogOpen={setIsDialogOpen} />
          ) : (
            <CreateCategory setIsDialogOpen={setIsDialogOpen} />
          )}
        </div>
      </CustomDialog>
    </div>
  );
};

export default Category;
