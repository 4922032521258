// @ts-expect-error
import moment from 'moment';

export default function calculateAge(dateOfBirth: Date) {
  if (!moment(dateOfBirth).isValid()) {
    return 'NaN';
  }

  const dob = new Date(dateOfBirth);
  const today = new Date();
  const yearsDiff = today.getFullYear() - dob.getFullYear();
  if (today.getMonth() < dob.getMonth() || (today.getMonth() === dob.getMonth() && today.getDate() < dob.getDate())) {
    return yearsDiff - 1;
  }
  return yearsDiff;
}
