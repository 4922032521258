import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import apiClient from '../apiClient';
import { usersKeys } from './key';

export const useDeleteUser = ({
  onSuccess,
  ...options
}: Omit<UseMutationOptions<any, unknown, string>, 'queryFn'> = {}) => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, string>(
    async (payload: string) => {
      console.log(payload);
      return await apiClient
        .delete('/users/' + payload)
        .then((res) => res.data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          usersKeys.getAllUser('', '', '', 'roleId'),
        );
      },
      ...options,
    },
  );
};
