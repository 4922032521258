import { UseFormRegister } from 'react-hook-form';

type InputBoxProps = {
  type: string;
  id: string;
  register: UseFormRegister<any>;
  name: string;
  placeholder?: string;
  defaultValue?: string;
  error?: string;
};
const InputBox = ({ type, id, name, register, placeholder, defaultValue, error }: InputBoxProps) => {
  return (
    <div className=" w-full">
      <div className="relative w-full">
        <input
          type={type}
          id={id}
          defaultValue={defaultValue}
          {...register(name)}
          className="peer block w-full appearance-none rounded-xl border-2 border-primary bg-transparent p-3 px-2.5 text-sm  text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
          placeholder={placeholder}

          // onChange={() => {}}
        />
        <label
          htmlFor={id}
          className="absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-white px-2 text-sm  text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500"
        >
          {placeholder}
        </label>
      </div>
      <p className=" text-red-600">{error}</p>
    </div>
  );
};

export default InputBox;
