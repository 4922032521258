import CustomModal from '@/components/common/CustomModal';
import IconClose from '@/components/common/icons/IconClose';
import CreateNotification from '@/components/CreateNotification';
import NotificationTable from '@/components/news/NotificationTable';

const PushNotiPage = () => {
  return (
    <section className="space-y-3 p-3">
      <CustomModal
        modalCloseButton={(closeModal) => (
          <button className="absolute z-40 right-5 top-2.5" onClick={closeModal}>
            <IconClose />
          </button>
        )}
        modalOpenButton={(openModal) => (
          <button
            onClick={openModal}
            className="rounded-full border border-[#3A454F] bg-white px-5 py-3 text-[14px] text-[#3A454F]"
          >
            + Create push notification
          </button>
        )}
        children={() => <CreateNotification />}
      />

      <NotificationTable />
    </section>
  );
};

export default PushNotiPage;
