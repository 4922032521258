import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import apiClient from '../apiClient';

type updatePassword = {
  oldPassword: string;
  password: string;
  confirmedPassword: string;
};
export const useChangePassword = () => {
  return useMutation<AxiosResponse, unknown, updatePassword>(
    async (payload: updatePassword) => {
      return await apiClient
        .post('/auth/update-password', payload)
        .then((res) => res.data)
        .catch((err) => err.message);
    },
  );
};
