import { SVGProps } from 'react';

const IconClose = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      fill="none"
      viewBox="0 0 33 33"
      {...props}
    >
      <circle cx="16.5" cy="16.5" r="16.5" fill="#CFE5F7"></circle>
      <path
        stroke="#89B0CF"
        strokeLinecap="round"
        strokeWidth="2.538"
        d="M11.871 11.422l10.154 10.154M21.578 11.422L11.424 21.576"
      ></path>
    </svg>
  );
};

export default IconClose;
