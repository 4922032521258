import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import apiClient from '../apiClient';
import { videosKey } from '@/api/video/key.ts';

export const useDeleteVideo = ({
  onSuccess,
  ...options
}: Omit<UseMutationOptions<any, unknown, string>, 'queryFn'> = {}) => {
  const queryClient = useQueryClient();
  return useMutation<any, AxiosError, string>(
    async (payload: string) => {
      console.log(payload);
      return await apiClient
        .delete('/videos/' + payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          videosKey.getAllVideos('1', '10', '', ''),
        );
      },
      ...options,
    },
  );
};
