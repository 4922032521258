import { Link, useNavigate } from 'react-router-dom';
import { AlignJustify, ChevronLeft, ChevronRight, Maximize2Icon, MaximizeIcon, Minimize2Icon, X } from 'lucide-react';
import { Dispatch, SetStateAction, useState } from 'react';
import Profile from './MenuList';
import Notifications from './Notification';

type HeaderProps = {
  expand: boolean;
  setExpand: Dispatch<SetStateAction<boolean>>;
};
const Header = ({ expand, setExpand }: HeaderProps) => {
  const navigate = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Handle to toggle full screen
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullScreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullScreen(false);
      });
    }
  };
  return (
    <div className="flex h-16 w-full items-center justify-between  px-8">
      <div className="flex items-center gap-4">
        <div className="h-5 w-5" onClick={() => setExpand(!expand)}>
          {!expand ? (
            <AlignJustify className="h-full w-full text-primary-dark" />
          ) : (
            <X className="h-full w-full text-primary-dark" />
          )}
        </div>
        <Link to="/">
          <img src="/mainlogo.png" alt="main-logo" className="h-8 w-36" />
        </Link>
        <div className="ml-9 flex items-center justify-center gap-5">
          <button className=" flex items-center  rounded-full hover:text-primary" onClick={() => navigate(-1)}>
            <ChevronLeft /> Back
          </button>
          <button className=" flex  items-center rounded-full hover:text-primary" onClick={() => navigate(+1)}>
            Forward <ChevronRight />
          </button>
        </div>
      </div>

      <div className="flex items-center gap-6">
        {/* <SearchBar /> */}

        <div className="z-30 mb-2 h-4 w-4 cursor-pointer" onClick={toggleFullScreen}>
          {isFullScreen ? <Minimize2Icon /> : <Maximize2Icon />}
        </div>

        <Notifications />

        <Profile />
      </div>
    </div>
  );
};
export default Header;
