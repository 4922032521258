import EditForm from '@/components/user/edit-user-from';
import { useAppSelector } from '@/store/hook';

const EditUser = ({ setUserRoleDial }: { setUserRoleDial: (isOpen: boolean) => void }) => {
  const user = useAppSelector((state) => state.UserSlice);

  return (
    <>
      <div className="flex w-[400px] flex-col items-center justify-center gap-3 rounded-xl bg-white p-10">
        <h1 className=" text-center text-lg font-bold">Edit User</h1>
        <EditForm data={user} setUserRoleDial={setUserRoleDial} />
      </div>
    </>
  );
};

export default EditUser;
