import { TagPayload } from '@/types/category';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const TagSlice = createSlice({
  name: 'tags',
  initialState: { id: '', name: '', thumbnailUrl: '' },
  reducers: {
    setTag: (state, action: PayloadAction<TagPayload>) => (state = action.payload),
  },
});

export default TagSlice.reducer;
export const { setTag } = TagSlice.actions;
