import { ChevronDown, X } from 'lucide-react';
import { useState } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types';
type category = {
  id: string;
  name: string;
  thumbnailUrl: string;
  createdAt: string;
  updatedAt: string;
};
type MultiSelectProps = {
  options?: category[];
  register: UseFormRegister<any>;
  setSelectedTags: (selected: any) => void;
  selectedTags: string;
  error: any;
};
const MultiSelect = ({ error, options, register, setSelectedTags, selectedTags }: MultiSelectProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<category[] | undefined>(options);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase();
    const filtered = options?.filter((option) => option.name.toLowerCase().includes(inputValue));
    setFilteredOptions(filtered);
    setShowOptions(true);
  };

  const handleOptionClick = (item: string) => {
    setSelectedTags(item);
    setShowOptions(false);
  };
  return (
    <div className="relative flex w-full flex-col">
      <div
        onClick={() => setShowOptions(!showOptions)}
        className="flex w-full items-center justify-center rounded-xl border-2 border-blue-500 p-2.5 outline-none "
      >
        <input
          type="text"
          {...register('tagIds', { onChange: handleInputChange })}
          placeholder="Enter tags name..."
          autoComplete="off"
          defaultValue=""
          className="w-full rounded-xl border-none   outline-none "
        />
        <ChevronDown />
      </div>
      {error && <p className=" text-red-500">Choose at least one tags!</p>}
      <div className=" flex flex-wrap gap-1">
        {selectedTags !== '' && (
          <p
            className=" mt-2 flex cursor-pointer gap-2 rounded-lg bg-slate-200 p-2"
            onClick={() => setSelectedTags('')}
          >
            {selectedTags} <X className=" rounded-full bg-white p-1 text-sm" />
          </p>
        )}
      </div>
      <div
        className={`absolute top-12 z-50 max-h-[300px] w-full overflow-y-scroll rounded-md border bg-slate-300 ${
          !showOptions && 'hidden'
        }`}
      >
        {filteredOptions?.map((option) => (
          <p
            key={option.id}
            onClick={() => handleOptionClick(option.name)}
            className={' w-full border-b bg-white p-3 hover:bg-slate-200'}
          >
            {option.name}
          </p>
        ))}
      </div>
    </div>
  );
};

export default MultiSelect;
