import { Loader2 } from 'lucide-react';
const QuickLoader = () => {
  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center text-2xl ">
      <Loader2 className="h-12 w-12 animate-spin text-primary" />
    </div>
  );
};

export default QuickLoader;
