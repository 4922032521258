import { useEffect, useState } from 'react';
import Odometer from 'react-odometerjs';
import { Link, useLocation } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import QuickLoader from '@/components/common/QuickLoader';
import { X } from 'lucide-react';
import { useConnectSocket } from '@/api/socketClient';

const UserCountdown = () => {
  const location = useLocation();
  const numberOfuser = location.state;
  const [userCount, setUserCount] = useState(numberOfuser);
  const { socket } = useConnectSocket();

  useEffect(() => {
    if (!socket) return;
    socket.on('userCount', (data: number) => {
      setUserCount(data);
    });
  }, [socket]);
  return (
    <div className=" relative flex min-h-[80vh] items-center justify-center">
      <Link className=" absolute right-5 top-5" to={'/'}>
        <X />
      </Link>
      {userCount ? (
        <div className="">
          <h2 className=" text-center">Total users registered</h2>
          <Odometer
            className="text-[10vw]"
            defaultValue={numberOfuser}
            value={userCount}
            format="(,ddd)"
          />
        </div>
      ) : (
        <QuickLoader />
      )}
    </div>
  );
};

export default UserCountdown;
