import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const BannerSlice = createSlice({
  name: 'tags',
  initialState: { id: '', name: '', link: '', content: '', imageUrl: '', isPublished: false },
  reducers: {
    setBanner: (
      state,
      action: PayloadAction<{
        id: string;
        name: string;
        link: string;
        content: string;
        imageUrl: string;
        isPublished: boolean;
      }>,
    ) => (state = action.payload),
  },
});

export default BannerSlice.reducer;
export const { setBanner } = BannerSlice.actions;
