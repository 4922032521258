import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '../apiClient';

export const useCreateNotification = () => {
  const querClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: FormData) => {
      return await apiClient.post('notifications', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    onSuccess: () => {
      querClient.invalidateQueries({
        queryKey: ['GetNotifications'],
        exact: false,
      });
    },
  });
};
