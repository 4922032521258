import { NavLink, useLocation } from 'react-router-dom';
import { forwardRef } from 'react';
import { cn } from '@/utils';
import { SideBarProps } from '@/types/sidebar';
import { ChevronDown, ChevronRight } from 'lucide-react';

type MenuItemProps = {
  item: SideBarProps;
  onClick: (item: any) => void;
  expand: boolean;
  showSub: boolean;
};

const MenuItem = forwardRef(
  ({ item, onClick, expand, showSub }: MenuItemProps, ref: React.LegacyRef<HTMLDivElement>) => {
    const location = useLocation();
    const currentPath = location.pathname.split('/')[1];
    const isActive = currentPath
      ? item.path.split('/')[1]?.includes(currentPath)
      : item.path.split('/')[1] === currentPath;

    return (
      <div className={cn('rounded-md', isActive ? 'bg-primary/20' : 'bg-transparent')}>
        <NavLink
          end
          onClick={() => onClick(item)}
          to={item.path}
          style={{
            textDecoration: 'none',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          <div className={cn('flex items-center justify-between p-3.5', isActive ? 'text-primary' : 'text-gray-500')}>
            <div className="flex items-center gap-4">
              <div className="h-6 w-6">{item.icon}</div>
              {expand && <h3 className="text-base font-medium">{item.title}</h3>}
            </div>
            {item?.nestedRoutes?.length &&
              expand &&
              (showSub ? <ChevronDown className="w-3 text-black" /> : <ChevronRight className="w-3 text-black" />)}
          </div>
        </NavLink>
        {item?.nestedRoutes && expand && (
          <div ref={ref} className="max-h-[0] overflow-hidden transition-[max-height] duration-300">
            {item?.nestedRoutes.map((subItem: SideBarProps, index: number) => (
              <NavLink
                key={index}
                to={subItem.path}
                style={{
                  textDecoration: 'none',
                  color: 'none',
                }}
              >
                <div
                  className={cn(
                    'flex items-center justify-start py-4 pl-1.5 text-primary-dark ',
                    subItem.path === location.pathname ? 'font-semibold' : 'font-normal',
                  )}
                >
                  <div className="h-6 w-6">{subItem.icon}</div>
                  {expand && <h3 className="text-base ease-in">{subItem.title}</h3>}
                </div>
              </NavLink>
            ))}
          </div>
        )}
      </div>
    );
  },
);

export default MenuItem;
