import { LayoutDashboard, Menu, Newspaper, Settings, Users, YoutubeIcon } from 'lucide-react';
const pageLimit = JSON.parse(localStorage.getItem('pageLimit')!) || 5;
export const menuItems = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <LayoutDashboard className="h-full w-full" />,
  },
  {
    title: 'Videos',
    path: `/videos?page=1&pageLimit=5`,
    icon: <YoutubeIcon className="h-full w-full" />,
  },
  {
    title: 'Home',
    path: '/home',
    icon: <Menu className="h-full w-full" />,
  },
  {
    title: 'News',
    path: '/news',
    icon: <Newspaper className="h-full w-full" />,
  },
  {
    title: 'Users',
    path: `/users?role=User&page=1&pageLimit=${pageLimit}&country=Myanmar`,
    icon: <Users className="h-full w-full" />,
  },
  {
    title: 'Setting',
    path: '/setting?tab=Profile',
    icon: <Settings className="h-full w-full" />,
  },
].filter(Boolean);
