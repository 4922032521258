import { UseFormRegister } from 'react-hook-form';

type InputBoxProps = {
  register: UseFormRegister<any>;
  name: string;
  placeholder: string;
  error?: boolean;
  id: string;
  setThumbnail: (video: any) => void;
};
const ThumbnailBox = ({ error, name, setThumbnail, register, id, placeholder }: InputBoxProps) => {
  const handleChange = (e: any) => {
    const video = e.target.files[0];
    setThumbnail(video);
  };
  return (
    <div className="relative w-full">
      <input
        type="file"
        {...(register(name), { onChange: handleChange })}
        className="peer block w-full appearance-none rounded-xl border-2 border-primary bg-transparent p-3 px-2.5 text-sm  text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
        placeholder={placeholder}
      />
      {error && <p className=" text-red-500">{name} is required!</p>}
      <label
        htmlFor={id}
        className="absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-white px-2 text-sm  text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500"
      >
        {placeholder}
      </label>
    </div>
  );
};

export default ThumbnailBox;
