import { Search } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { Button, buttonVariants } from '../common/Button';
import { cn } from '@/utils';
import useSetUrlQuery from '@/utils/query';

type TopBarOfTagProps = {
  setIsDialogOpen: (isOpen: boolean) => void;
  setIsEditMode: (isOpen: boolean) => void;
};
const TopBarOfTag = ({ setIsDialogOpen, setIsEditMode }: TopBarOfTagProps) => {
  const { setQuery, getQuery } = useSetUrlQuery();
  const { handleSubmit, register } = useForm();
  const searchTags = (data: any) => {
    setQuery({ key: 'tag', value: data.search, backToFirstPage: true });
  };
  return (
    <div className="flex items-center justify-center gap-3">
      <form
        onSubmit={handleSubmit(searchTags)}
        className="flex h-[40px] items-center justify-between overflow-hidden rounded-lg border border-primary bg-white focus:ring-2"
      >
        <input
          type="text"
          defaultValue={getQuery('tag')}
          placeholder="search videos..."
          className=" h-full bg-white px-3 outline-none focus:bg-white active:bg-white"
          {...register('search')}
        />
        <button>
          <Search className=" h-full w-full bg-primary p-2  text-white" />
        </button>
      </form>
      <Button
        className={cn(buttonVariants({ variant: 'transparent' }), 'rounded-md py-1.5 text-sm')}
        onClick={() => {
          setIsDialogOpen(true);
          setIsEditMode(false);
        }}
      >
        Add Sub Category
      </Button>
    </div>
  );
};

export default TopBarOfTag;
