import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '../apiClient';
import {
  CreateNewRequest,
  CreateNewResponse,
  NewErrorResponse,
  UploadImageResponse,
} from '@/types/news';
import axios, { AxiosError } from 'axios';
import { newsKeys } from './news.key';

export const useCreateNewsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<
    CreateNewResponse,
    AxiosError<NewErrorResponse>,
    CreateNewRequest
  >({
    mutationFn: async (data) => {
      return await apiClient
        .post('/news-feed/posts', data)
        .then((res) => res.data);
    },
    onSuccess: async () => {
      return await queryClient.invalidateQueries({
        queryKey: newsKeys.getAllNews(),
      });
    },
  });
};

export const useGetFileUploadUrlMutation = () => {
  return useMutation<UploadImageResponse>({
    mutationFn: async () => {
      return await apiClient
        .post('/news-feed/upload-image')
        .then((res) => res.data);
    },
  });
};

export const useFileUploadMutation = () => {
  return useMutation({
    mutationFn: async ({ url, file }: { url: string; file: File }) => {
      return await axios
        .put(url, file, {
          headers: {
            'Content-Type': file.type,
          },
        })
        .then((res) => res.data);
    },
  });
};

export const useDeleteNewMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      return await apiClient
        .delete(`/news-feed/posts/${id}`)
        .then((res) => res.data);
    },
    onSuccess: async () => {
      return await queryClient.invalidateQueries({
        queryKey: newsKeys.getAllNews(),
      });
    },
  });
};

export const useUpdateNewMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation<
    CreateNewResponse,
    AxiosError<NewErrorResponse>,
    CreateNewRequest
  >({
    mutationFn: async (data) => {
      return await apiClient
        .patch(`/news-feed/posts/${id}`, data)
        .then((res) => res.data);
    },
    onSuccess: async () => {
      return await queryClient.invalidateQueries({
        queryKey: newsKeys.getAllNews(),
      });
    },
  });
};
