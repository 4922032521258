import { User } from 'lucide-react';
import { Button } from '@/components/common/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import calculateAge from '@/utils/getAge';
// @ts-expect-error
import moment from 'moment';
import { useDeleteUser } from '@/api/users/useDeleteUser';
import { toast } from 'react-toastify';

const UserDetail = () => {
  const location = useLocation();
  const user = location.state;
  const age = calculateAge(user.dob);
  const { mutate } = useDeleteUser();
  const navigate = useNavigate();

  const handleDeleteUser = (id: string) => {
    if (confirm('Are you sure to delete')) {
      mutate(id, {
        onSuccess() {
          toast.success('update successfully');
          navigate('/users?role=User&page=1&pageLimit=20');
        },
      });
    }
  };
  return (
    <div className="m-8 flex flex-1 gap-4">
      {/* <div className="flex h-full w-[300px] flex-col gap-4">
        <div className="flex w-full flex-1 flex-col gap-2 rounded-xl bg-white shadow-md">
          <h3 className="border-b border-gray-300 px-6 py-4 text-left text-[20px] font-medium">User Info</h3>
          {[1, 2, 3, 4, 5].map((index) => {
            return (
              <p key={index} className=" w-full cursor-pointer p-5  text-start hover:bg-slate-100">
                Tab {index}
              </p>
            );
          })}
        </div>
      </div> */}
      <div className="flex w-full  flex-1 flex-col rounded-xl bg-white p-5 shadow-md">
        <div className="flex w-full items-start justify-between">
          <div className="flex items-center justify-center">
            <User className=" h-20 w-20" />
            <div className="">
              <p className=" text-xl">{user.nickname}</p>
              <p className=" text-sm">{user.role}</p>
            </div>
          </div>
          {user.role == 'Super Admin' ? (
            <Button disabled className=" cursor-none">
              This account can't be deleted!!!
            </Button>
          ) : (
            <div className="flex items-center gap-3">
              <Button className=" flow-root rounded-xl bg-yellow-500">Ban user</Button>
              <Button className=" flow-root rounded-xl bg-red-500" onClick={() => handleDeleteUser(user.id)}>
                Delete User
              </Button>
              {/* <Button className=" flow-root rounded-xl bg-red-500">Deactivate user</Button> */}
            </div>
          )}
        </div>
        <p> User Name : {user.username}</p>
        <p>Phone : {user?.phone}</p>
        <p>
          Date of Birth :{' '}
          {moment(user.dob).format('MMM D, YYYY') == 'Invalid date' ? 'NaN' : moment(user.dob).format('MMM D, YYYY')}
        </p>
        <p> Gender : {user.gender}</p>
        <p> Age : {age}</p>
      </div>
    </div>
  );
};
export default UserDetail;
