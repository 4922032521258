import { useGetCurrentUser } from '@/api/users/useGetCurrentUser';
//@ts-expect-error
import moment from 'moment';

import QuickLoader from '../common/QuickLoader';
import { useState } from 'react';
import CustomDialog from '../common/CustomDialog';
import { Edit2 } from 'lucide-react';
import EditProfile from './EditProfile';
import { useUpdateProfilePicture } from '@/api/users/useUpdateProfilePicture';
import { toast } from 'react-toastify';

const Profile = () => {
  const { data: user, isLoading } = useGetCurrentUser();
  const { mutate } = useUpdateProfilePicture();
  const [editImage, setEditImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(user?.imageUrl || '/ql_icon.png');
  const [image, setImage] = useState<any>();

  if (isLoading) {
    return <QuickLoader />;
  }
  const updateProfilePicture = async () => {
    let formData = new FormData();
    const id = user?.id;
    formData.append('avatar', image);
    mutate(
      { formData, id },
      {
        onError() {
          toast.error('error updating profile!');
        },
        onSuccess() {
          toast.success('updated profile successfully');
        },
      },
    );
  };
  return (
    <div className=" w-full p-5">
      <div className=" border-b border-slate-300 pb-5">
        <h3 className=" text-xl">Edit Profile</h3>
        <CustomDialog onClose={() => setEditImage(false)} open={editImage}>
          <div className="flex h-[300px] w-[400px] flex-col items-center justify-center rounded-2xl bg-white p-5">
            <img src={imageUrl} alt="profile image" className=" h-36 w-36 rounded-full object-cover" />
            <label htmlFor="profile" className=" absolute right-5 top-5 cursor-pointer rounded-full bg-slate-200 p-2">
              <Edit2 className="" />
            </label>
            <button className=" mt-3 rounded-sm bg-slate-300 p-2 text-white" onClick={updateProfilePicture}>
              Update
            </button>
            <input
              type="file"
              id="profile"
              className="hidden"
              onChange={(e) => {
                //@ts-expect-error
                const file = e.target.files[0];
                const url = URL.createObjectURL(file);
                setImageUrl(url);
                setImage(file);
              }}
            />
          </div>
        </CustomDialog>
      </div>
      <EditProfile user={user} imageUrl={imageUrl} setEditImage={setEditImage} />
    </div>
  );
};

export default Profile;
