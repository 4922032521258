export const profileMenu = [
  {
    name: 'View Profile',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <path
          d="M15.9073 16.5092V14.5208C15.9073 13.4661 15.4883 12.4545 14.7425 11.7087C13.9967 10.9629 12.9852 10.5439 11.9305 10.5439H3.97683C2.92211 10.5439 1.91058 10.9629 1.16478 11.7087C0.418986 12.4545 0 13.4661 0 14.5208V16.5092"
          fill="#3A454F"
        />
        <path
          d="M7.95339 8.54398C10.1497 8.54398 11.9302 6.7635 11.9302 4.56716C11.9302 2.37082 10.1497 0.590332 7.95339 0.590332C5.75705 0.590332 3.97656 2.37082 3.97656 4.56716C3.97656 6.7635 5.75705 8.54398 7.95339 8.54398Z"
          fill="#3A454F"
        />
      </svg>
    ),
    to: 'setting?tab=Profile',
  },
  {
    name: 'Setting',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <path
          d="M13.8693 9.53399L13.8002 9.89079L14.1182 10.0666L15.4772 10.8179C15.1309 11.836 14.5633 12.759 13.8271 13.534L12.4488 12.7721L12.1533 12.6087L11.8913 12.8218C11.3312 13.2777 10.6894 13.6327 9.99691 13.8686L9.65814 13.984V14.3419V15.8339C8.59658 16.0501 7.45845 16.0606 6.34494 15.8339V14.3419V13.982L6.00369 13.8678C5.30953 13.6354 4.66944 13.2811 4.11403 12.8237L3.8517 12.6076L3.55426 12.7721L2.17607 13.5339C1.43996 12.7561 0.872631 11.8335 0.526313 10.819L1.88146 10.0698L2.19953 9.89402L2.13045 9.53721C1.9977 8.85161 1.9977 8.14839 2.13045 7.46279L2.19953 7.10599L1.88146 6.93016L0.526233 6.18098C0.872619 5.16328 1.44004 4.24077 2.17591 3.46604L3.55046 4.22769L3.84615 4.39154L4.10837 4.17817C4.66855 3.72235 5.31036 3.36726 6.0028 3.13138L6.34157 3.01597V2.65809V1.16609C7.40313 0.949947 8.54126 0.939381 9.65477 1.1661V2.65486V3.01473L9.99602 3.12899C10.6902 3.36142 11.3303 3.71564 11.8857 4.17307L12.148 4.38913L12.4454 4.2247L13.8236 3.46283C14.5598 4.24071 15.1271 5.1633 15.4734 6.1778L14.1182 6.92693L13.8002 7.10276L13.8693 7.45956C14.002 8.14517 14.002 8.84838 13.8693 9.53399ZM4.80242 8.49677C4.80242 10.2158 6.25678 11.5774 7.99817 11.5774C9.73956 11.5774 11.1939 10.2158 11.1939 8.49677C11.1939 6.77772 9.73956 5.41614 7.99817 5.41614C6.25678 5.41614 4.80242 6.77772 4.80242 8.49677Z"
          fill="#3A454F"
          stroke="#3A454F"
        />
      </svg>
    ),
    to: 'setting?tab=Security',
  },
];

export const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
      <path
        d="M6.19754 11.9783C6.09631 12.0194 5.9875 12.0038 5.90508 11.9236C5.90491 11.9234 5.90474 11.9233 5.90457 11.9231L0.574062 6.67701C0.573827 6.67678 0.573594 6.67654 0.57336 6.67631C0.475548 6.57827 0.475548 6.42239 0.57336 6.32435C0.573594 6.32412 0.573827 6.32389 0.574062 6.32365L5.90576 1.07641C5.98655 0.996891 6.09514 0.980348 6.19717 1.02156C6.2971 1.06193 6.35669 1.14291 6.35669 1.2512L6.35669 4.2507L6.35669 4.7507L6.85669 4.7507L11.1744 4.75071C11.328 4.75071 11.4363 4.86866 11.4363 5.00058L11.4363 8.00008C11.4363 8.132 11.328 8.24996 11.1744 8.24996L6.85669 8.24996L6.35669 8.24996L6.35669 8.74996L6.35669 11.7495C6.35669 11.8565 6.29755 11.9377 6.19754 11.9783ZM10.4045 2.001L10.4045 1.00133L12.9522 1.00133C14.3663 1.00133 15.5 2.12849 15.5 3.50083L15.5 9.49983C15.5 10.8722 14.3663 11.9993 12.9522 11.9993L10.4045 11.9993L10.4045 10.9997L12.9522 10.9997C13.7827 10.9997 14.4682 10.3365 14.4682 9.49983L14.4682 3.50083C14.4682 2.66416 13.7828 2.001 12.9522 2.001L10.4045 2.001Z"
        fill="#3A454F"
        stroke="#243C4F"
      />
    </svg>
  );
};
