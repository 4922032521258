import { useQuery } from '@tanstack/react-query';
import apiClient from '../apiClient';
import { userRes } from '@/types/user';

export const useGetCurrentUser = () => {
  const refreshToken = localStorage.getItem('refresh_token');
  return useQuery<any, unknown, userRes>(['currentUser'], async () => {
    return await apiClient
      .get('/users/me')
      .then((res) => res.data)
      .catch(async (err) => {
        if (err?.response.status == 401) {
          const { data } = await apiClient.post('auth/refresh-token', {
            refreshToken,
          });
          localStorage.setItem('access_token', data.accessToken);
          localStorage.setItem('refresh_token', data.refreshToken);
        }
      });
  });
};
