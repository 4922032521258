import { useMutation, useQueryClient } from '@tanstack/react-query';

import apiClient from '../apiClient';

export const useCreatCategory = () => {
  const queryClient = useQueryClient();

  return useMutation<any, unknown, any>(
    async (payload) => {
      return await apiClient
        .post('/tags', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries({ queryKey: ['GetTags'] });
      },
    },
  );
};
