import { Category, TagPayload } from '@/types/category';
import { Edit, Trash } from 'lucide-react';

interface Props {
  tag: Category;
  handleEdit: (tag: TagPayload) => void;
  handleDelete: (tag: TagPayload) => void;
}
const MainCategoryCard = ({ tag, handleEdit, handleDelete }: Props) => {
  return (
    <div className=" relative h-[200px] w-[200px] rounded-md">
      <img src={tag.thumbnailUrl} alt="" className=" h-[160px] w-full p-3" />
      <p className="text-center">{tag.name}</p>
      <div className="absolute right-5 top-4 flex gap-x-2">
        <Edit
          onClick={(e) => {
            e.preventDefault();
            handleEdit({ id: tag.id, name: tag.name, thumbnailUrl: tag.thumbnailUrl });
          }}
          className="h-5 w-5 fill-primary stroke-white text-primary"
        />
        <Trash
          onClick={(e) => {
            e.preventDefault();
            handleDelete({ id: tag.id, name: tag.name, thumbnailUrl: tag.thumbnailUrl });
          }}
          className="h-5 w-5 fill-red-500 stroke-white text-red-500"
        />
      </div>
    </div>
  );
};

export default MainCategoryCard;
