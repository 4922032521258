import { Button, buttonVariants } from '@/components/common/Button';
import InputBox from '@/components/common/form/InputBox';
import { useForm } from 'react-hook-form';
import { cn } from '@/utils';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useCreateSlide } from '@/api/banners/useCreateSlide';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateSlideSchema } from '@/validators/slide.d';

type CreateSlideProps = {
  setIsDialogOpen: (isOpen: boolean) => void;
};
const CreateSlide = ({ setIsDialogOpen }: CreateSlideProps) => {
  const { mutate, isLoading } = useCreateSlide();
  const [webThumbnail, setWebThumbnail] = useState<Blob | null>(null);
  const [webImageUrl, setWebImageUrl] = useState('');
  const [mobileThumbnail, setMobileThumbnail] = useState<Blob | null>(null);
  const [mobileImageUrl, setMobileImageUrl] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      name: '',
      link: '',
      content: '',
    },
    resolver: yupResolver(CreateSlideSchema),
  });
  const createSlide = (data: any) => {
    const webFormData = new FormData();
    webFormData.append('name', data.name);
    data.link && webFormData.append('link', data.link);
    // webFormData.append('content', data.content);
    //@ts-expect-error
    webFormData.append('image', webThumbnail);
    webFormData.append('type', 'web');
    mutate(webFormData, {
      onSuccess() {
        setIsDialogOpen(false);
        // toast.success('Slide is successfully created');
      },
      onError(error: any) {
        console.log(error, 'shit');
        toast.error(
          error?.response.data.message ||
            error.message ||
            'Something went wrong!',
        );
      },
    });

    const mobileFormData = new FormData();
    mobileFormData.append('name', data.name);
    data.link && mobileFormData.append('link', data.link);
    // mobileFormData.append('content', data.content);
    //@ts-expect-error
    mobileFormData.append('image', mobileThumbnail);
    mobileFormData.append('type', 'mobile');
    mutate(mobileFormData, {
      onSuccess() {
        setIsDialogOpen(false);
        toast.success('Slide is successfully created');
      },
      onError(error: any) {
        toast.error(
          error?.response.data.message ||
            error.message ||
            'Something went wrong!',
        );
      },
    });
  };

  return (
    <div className=" m-5  rounded-xl bg-white p-5">
      <h3 className=" mb-4 text-lg font-bold">Create New Slide</h3>
      <form
        onSubmit={handleSubmit(createSlide)}
        className="flex flex-col items-center justify-center"
      >
        <div className="w-full">
          <InputBox
            placeholder="Enter slide name"
            type="text"
            id="name"
            name="name"
            register={register}
          />
          <p className="mt-2 text-red-700">{errors.name?.message}</p>
        </div>
        {/* <div className="w-full">
          <InputBox
            placeholder="Enter content"
            type="text"
            id="content"
            name="content"
            register={register}
          />
          <p className="mt-2 text-red-700">{errors.content?.message}</p>
        </div> */}
        <div className="w-full">
          <InputBox
            placeholder="Link"
            type="text"
            name="link"
            id="link"
            register={register}
          />
          <p className="mt-2 text-red-700">{errors.link?.message}</p>
        </div>
        <div className="flex w-full gap-x-2">
          <div className="relative w-full">
            <label
              htmlFor="web_banner"
              {...register('web_banner')}
              className={cn(
                'block h-[90px] w-full overflow-hidden rounded-2xl border-2 border-primary text-center',
                !webImageUrl && 'py-10',
              )}
            >
              {webImageUrl ? (
                <img src={webImageUrl} alt="" className=" h-full w-full" />
              ) : (
                <div className="absolute right-[20%] top-[20%] mt-2 text-sm">
                  <p>+</p>
                  <p>1080x340px</p>
                </div>
              )}
            </label>
            <input
              type="file"
              className="hidden"
              id="web_banner"
              onChange={(e) => {
                if (e.target.files) {
                  const file = e.target.files[0];
                  const url = URL.createObjectURL(file);
                  setWebImageUrl(url);
                  setWebThumbnail(file);
                  setValue('web_banner', file);
                  trigger('web_banner');
                }
              }}
            />
            <p className="mt-2 text-red-700">{errors.web_banner?.message}</p>
          </div>
          <div className="relative w-full">
            <label
              htmlFor="mobile_banner"
              {...register('mobile_banner')}
              className={cn(
                'block h-[90px] w-full overflow-hidden rounded-2xl border-2 border-primary text-center',
                !mobileImageUrl && 'py-10',
              )}
            >
              {mobileImageUrl ? (
                <img src={mobileImageUrl} alt="" className=" h-full w-full" />
              ) : (
                <div className="absolute right-[22%] top-[20%] mt-2 text-sm">
                  <p>+</p>
                  <p>350x180px</p>
                </div>
              )}
            </label>
            <input
              type="file"
              className="hidden"
              id="mobile_banner"
              onChange={(e) => {
                if (e.target.files) {
                  const file = e.target.files[0];
                  const url = URL.createObjectURL(file);
                  setMobileImageUrl(url);
                  setMobileThumbnail(file);
                  setValue('mobile_banner', file);
                  trigger('mobile_banner');
                }
              }}
            />
            <p className="mt-2 text-red-700">{errors.mobile_banner?.message}</p>
          </div>
        </div>
        <Button
          isLoading={isLoading}
          className={cn(
            buttonVariants({ variant: 'default', size: 'sm' }),
            'mt-4 px-6 text-sm',
          )}
        >
          Create
        </Button>
      </form>
    </div>
  );
};

export default CreateSlide;
