import { toast } from 'react-toastify';
import { Button } from '../common/Button';
import CustomDialog from '../common/CustomDialog';

type ProgressProps = {
  isLoading: boolean;
  loaded: number;
};
const UploadProgress = ({ isLoading, loaded }: ProgressProps) => {
  return (
    <CustomDialog open={isLoading} onClose={() => {}}>
      <div className="progressBar flex w-[400px] flex-col items-center justify-center gap-4 rounded-xl border-2 border-blue-500 bg-white p-8">
        <div className=" relative flex w-full items-center justify-center gap-2">
          <input
            type="range"
            id="progressBar"
            value={loaded}
            max={100}
            min={0}
            className=" custom-range mr-2 w-full cursor-default rounded-none border border-black"
          />
          <p className=" font-light">{loaded}%</p>
        </div>
        <Button
          variant="transparent"
          className=" w-[100px] rounded-2xl"
          onClick={() => {
            toast.error('user cancel upload');
          }}
        >
          Cancel
        </Button>
      </div>
    </CustomDialog>
  );
};

export default UploadProgress;
