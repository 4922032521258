import { useQuery } from '@tanstack/react-query';

import apiClient from '../apiClient';
import { AllVideoRes } from '@/types/videos';
import { videosKey } from '@/api/video/key.ts';

export const useGetAllVideos = (
  page?: string,
  pageLimit?: string,
  All?: string,
  tags?: string,
) => {
  return useQuery<AllVideoRes, unknown, any>(
    videosKey.getAllVideos(page, pageLimit, All, tags),
    async () => {
      return await apiClient
        .get('/videos', {
          params: tags
            ? {
                page,
                limit: pageLimit,
                search: All?.toLowerCase(),
                tags,
              }
            : {
                page,
                limit: pageLimit,
                search: All?.toLowerCase(),
              },
        })
        .then((res) => res.data);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 60000,
      cacheTime: 60000,
      refetchInterval: 60000,
    },
  );
};
