import useSetUrlQuery from '@/utils/query';
import { storeInLocal } from '@/utils/storeInLocal';
import ReactPaginate from 'react-paginate';

type PageProps = {
  total: number;
  pageCount: number;
};

const PaginateFoot = ({ pageCount, total }: PageProps) => {
  const { setQuery, getQuery } = useSetUrlQuery();
  const onHandlePageChange = (selectedItem: { selected: number }) => {
    setQuery({ key: 'page', value: String(selectedItem.selected + 1), backToFirstPage: false });
  };
  const itemPerView = getQuery('pageLimit');
  const onHandlePageLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setQuery({ key: 'pageLimit', value: e.target.value, backToFirstPage: true });
    storeInLocal('pageLimit', e.target.value);
  };
  const currentPage = Number(getQuery('page')) - 1;
  return (
    <div className="paginate  flex items-center justify-between">
      <ReactPaginate
        pageCount={pageCount}
        breakLabel="..."
        activeClassName="bg-primary p-2 text-white rounded-full"
        // pageClassName="px-4 py-2 "
        pageLinkClassName="p-2"
        initialPage={currentPage}
        nextLabel="next >"
        onPageChange={onHandlePageChange}
        pageRangeDisplayed={5}
        breakClassName="px-3 py-1"
        className="flex items-center justify-center gap-3 "
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
      <p className=" text-xl text-primary">Total : {total}</p>
      <select
        name="pageSize"
        onChange={onHandlePageLimitChange}
        className="rounded-2xl border-2 border-primary p-3 outline-0"
        value={itemPerView}
      >
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
      </select>
    </div>
  );
};

export default PaginateFoot;
