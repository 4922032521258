import { toast } from 'react-toastify';
import { Button } from '../common/Button';

const ManageAccount = () => {
  const handleAccDelete = () => {
    console.log('hello');
    toast.success('your account have been deleted successfuly!');
    setTimeout(() => {
      toast.error("just kidding, We don't all you to delete easily haha");
    }, 3000);
  };
  return (
    <div className=" w-full p-5">
      <div className=" border-b border-slate-300 pb-5">
        <h3 className=" text-xl">Manage Account</h3>
      </div>
      <div className=" mt-5">
        <h3 className=" text-xl text-red-500">Delete Account</h3>
        <Button variant="transparent" className="mt-5 border-2 border-red-500 text-red-500" onClick={handleAccDelete}>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default ManageAccount;
