import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// components
import { Button } from '@/components/common/Button';
import InputBox from '@/components/common/form/InputBox';
import { useAuthLogin } from '@/api/auth/useAuthLogin.ts';
// type
import { AuthPayload } from '@/types/auth';
// lib
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useAuthLogin();
  const access_token = localStorage.getItem('access_token');
  const refresh_token = localStorage.getItem('refresh_token');
  if (access_token && refresh_token) {
    navigate('/');
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthPayload>({
    defaultValues: {
      // username: 'sysadmin',
      // password: 'Admin@001',
    },
    //resolver: yupResolver(loginValidationSchema),
  });
  const onSubmit: SubmitHandler<AuthPayload> = (data: AuthPayload) => {
    mutate(data, {
      onSuccess(data) {
        console.log(data);

        navigate('/');
        localStorage.setItem('access_token', data.accessToken);
        localStorage.setItem('refresh_token', data.refreshToken);
        toast.success('Successfully Login');
      },
      onError() {
        toast.error('Something Wrong!');
      },
    });
  };
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center rounded-xl bg-white shadow-md">
      <h1 className="my-8 text-center text-2xl font-bold text-primary">Sign In</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="my-8 space-y-4 px-8">
        <div className="flex flex-col gap-2">
          <InputBox
            type="text"
            id="username"
            register={register}
            name="username"
            placeholder="Enter your admin username"
          />
          {errors.username && (
            <p className="text-sm text-red-500" role="alert">
              {errors.username.message}
            </p>
          )}
        </div>
        <div className="flex w-[300px] flex-col gap-2">
          <InputBox
            type="password"
            id="password"
            register={register}
            name="password"
            placeholder="Enter your password"
          />
          {errors.password && (
            <p className="text-sm text-red-500" role="alert">
              {errors.password.message}
            </p>
          )}
        </div>

        <Button isLoading={isLoading} className="w-full rounded-md bg-primary">
          Sign In
        </Button>
        <div className="mt-4 cursor-pointer text-sm text-primary underline"></div>
      </form>
    </div>
  );
};
export default SignIn;
