import { useUpdateUserRole } from '@/api/user-role/useUpdateUserRole';
import { Button } from '@/components/common/Button';
import { userRes } from '@/types/user';
//@ts-expect-error
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

type EditFormProps = {
  data: userRes;
  setUserRoleDial: any;
};

const EditForm = ({ data, setUserRoleDial }: EditFormProps) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      role: data?.role,
      id: data?.id,
      status: data.status,
    },
  });
  const { mutate } = useUpdateUserRole();
  const userRoles = ['User', 'Admin', 'SuperAdmin'];
  const updateUserInfo = (data: any) => {
    mutate(
      { id: data.id, data },
      {
        onSuccess() {
          toast.success('update successfully');
          setUserRoleDial(false);
        },
      },
    );
  };
  return (
    <form onSubmit={handleSubmit(updateUserInfo)} className=" flex w-full flex-col items-center justify-center gap-3">
      <select
        {...register('role')}
        defaultValue={data.role}
        className="peer block w-full appearance-none rounded-xl border-2 border-primary bg-transparent p-3 px-2.5 text-sm  text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
      >
        {userRoles?.map((role) => {
          return (
            <option value={role} key={role}>
              {role}
            </option>
          );
        })}
      </select>
      <div className="flex w-full justify-between">
        <div className="">
          <input type="radio" checked value="ACTIVE" {...register('status')} /> Unrestricted
        </div>
        <div className="">
          <input type="radio" value="TERMINATED" {...register('status')} /> Restricted
        </div>
      </div>
      <Button variant="transparent" className=" mt-3 w-[200px] rounded-xl">
        Update
      </Button>
    </form>
  );
};

export default EditForm;
