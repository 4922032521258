import { Button, buttonVariants } from '@/components/common/Button';
import InputBox from '@/components/common/form/InputBox';
import { addCategoryValidationSchema } from '@/validators/category';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { cn } from '@/utils';
import { toast } from 'react-toastify';
import { useUpdateCategory } from '@/api/category/useUpdateCategory';
import { useAppSelector } from '@/store/hook';
import { useState } from 'react';
import IconClose from '@/components/common/icons/IconClose';

type CreateCategoryProps = {
  setIsDialogOpen: (isOpen: boolean) => void;
};

const UpdateCategory = ({ setIsDialogOpen }: CreateCategoryProps) => {
  const tag = useAppSelector((state) => state.tagSlice);
  const { mutate, isLoading, error } = useUpdateCategory();
  const [thumbnail, setThumbnail] = useState<Blob>();
  const [imageUrl, setImageUrl] = useState(tag.thumbnailUrl || '');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: tag.name,
    },
    resolver: yupResolver(addCategoryValidationSchema),
  });
  console.log(thumbnail);

  const addCate = async (data: { name: string }) => {
    let formData = new FormData();
    //@ts-expect-error
    formData.append('thumbnail', thumbnail);
    formData.append('name', data.name);
    mutate(
      { id: tag.id, category: formData },
      {
        onSuccess() {
          setIsDialogOpen(false);
          toast.success('Category updated successfully ');
        },
        onError() {
          //@ts-expect-error
          toast.error(error?.response?.data?.message);
        },
      },
    );
  };

  const clearImage = (e : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setImageUrl('');
  }

  return (
    <div className=" m-5  w-full rounded-xl bg-white p-5">
      <h3 className=" mb-4 text-lg font-bold">Edit Category Name</h3>
      <form onSubmit={handleSubmit(addCate)} className="flex flex-col items-center justify-center">
        <InputBox
          placeholder="Enter new category"
          type="text"
          id="tag"
          name="name"
          register={register}
          defaultValue={tag?.name}
        />

        <p className="mt-2 text-red-700">{errors?.name?.message}</p>
        <div className="w-full ">
          <label
            htmlFor="cat_image"
            className={cn(
              'block h-[200px] w-full overflow-hidden rounded-2xl border-2 border-primary text-center relative',
              !imageUrl && 'py-20',
            )}
          >
            {imageUrl ? <img src={imageUrl} alt="" className=" h-full w-full object-cover" /> : '+'}
            <button onClick={clearImage} className='absolute top-2 right-2'>
              <IconClose />
            </button>
          </label>
          <input
            type="file"
            className="hidden"
            id="cat_image"
            onChange={(e) => {
              if (e.target.files) {
                const file = e.target.files[0];
                const url = URL.createObjectURL(file);
                setImageUrl(url);
                setThumbnail(file);
              }
            }}
          />
          {/* <p className="mt-2 text-red-700">{errors.Image?.message}</p> */}
        </div>
        <Button
          type='submit'
          isLoading={isLoading}
          className={cn(buttonVariants({ variant: 'default', size: 'sm' }), 'mt-4 px-6 text-sm')}
        >
          Update
        </Button>
      </form>
    </div>
  );
};

export default UpdateCategory;
