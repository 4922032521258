'use client';
import { useRef, useState } from 'react';
import { Bell } from 'lucide-react';
import { cn } from '@/utils';
import { useGetNotifications } from '@/api/notifications/useGetNofifications';
import useOuterClick from '@/utils/useOuterClick';
import { useAckNotification } from '@/api/notifications/useAckNotification';
import { Link } from 'react-router-dom';

const Notifications = () => {
  const [openNotiBox, setOpenNotiBox] = useState(false);
  const { data, fetchNextPage } = useGetNotifications();
  const { mutate } = useAckNotification();
  const notiRef = useRef(null);
  const notificationList = data?.pages;
  const toggleNotiBox = () => {
    setOpenNotiBox(!openNotiBox);
  };
  useOuterClick(notiRef, toggleNotiBox);
  const handleAckNoti = (id: string) => {
    mutate(
      { id },
      {
        onSuccess() {
          console.log('acked');
        },
      },
    );
  };
  return (
    <div className="relative cursor-pointer">
      <div className="relative rounded-lg border border-slate-500 p-1" onClick={toggleNotiBox}>
        <Bell className=" text-xl text-black/80" />
        <div
          className={
            'absolute -right-1 top-0 z-30 flex items-center  justify-center overflow-hidden rounded-full bg-red-500 px-1 text-center text-[10px] text-white'
          }
        >
          {notificationList && notificationList[0]?.unreadCount}
        </div>
      </div>
      {openNotiBox && (
        <div
          ref={notiRef}
          className=" absolute right-0 z-40 mt-4 flex h-[80vh] w-[400px] flex-col gap-2 overflow-auto rounded-md bg-slate-200 p-2 shadow-md transition duration-300  ease-in-out"
        >
          {notificationList?.map((noti, index) => {
            return (
              <div className="" key={index}>
                {noti.data.map((notification) => {
                  return (
                    <Link
                      to={notification.url}
                      className={cn(
                        'flex w-full gap-3 rounded-md p-2 hover:bg-primary/20',
                        notification.acked && 'bg-white',
                      )}
                      key={notification.id}
                      onClick={() => handleAckNoti(notification.id)}
                    >
                      <div className="relative h-10 w-10 overflow-hidden rounded-full border border-primary/50">
                        <img src={notification.thumbnailUrl} alt="avatar" className="inline-block" />
                      </div>
                      <div className="flex w-56 flex-col">
                        <h3 className="text-base font-medium text-primary">{notification.title}</h3>
                        <p className="truncate  text-[12px] font-[300] text-black">{notification.body}...</p>
                      </div>
                    </Link>
                  );
                })}
              </div>
            );
          })}
          <button onClick={() => fetchNextPage()}>Load more...</button>
        </div>
      )}
    </div>
  );
};
export default Notifications;
