import { useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';

export const useConnectSocket = () => {
  const [socket, setSocket] = useState<Socket>();

  useEffect(() => {
    const client = io('https://qlbp.ilbc.edu.mm/users', { autoConnect: true });
    if (client) {
      setSocket(client);
    }
    return () => {
      socket && socket.disconnect();
    };
  }, []);
  return { socket };
};
