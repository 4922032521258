import { object, ref, string } from 'yup';

export const phoneRegex = /^9[0-9]{7,9}$/g;

const getCharacterValidationError = (str: string): string => {
  return `must have at least 1 ${str} character`;
};

export const loginValidationSchema = object({
  phone: string()
    .required('Phone number is required')
    .matches(phoneRegex, 'Phone number is not valid')
    .min(9, 'Invalid phone number')
    .max(11, 'Invalid phone number'),
  password: string()
    .required('Please enter a password')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
});

export const updatePhoneValidationSchema = object({
  phone:
    string()
      .required('Current phone number is required')
      .matches(phoneRegex, 'Phone number is not valid')
      .min(9, 'Invalid phone number')
      .max(11, 'Invalid phone number') || undefined,
});

export const updatePasswordValidationSchema = object({
  oldPassword: string()
    .required('Enter old password')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
  password: string()
    .required('Please enter a  new password')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
  confirmedPassword: string()
    .required('Please confirm your new password')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
    .oneOf([ref('password')], 'passwords do not matched!'),
});
