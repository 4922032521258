import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '../apiClient';
import { AxiosResponse } from 'axios';
export const useUpdateProfilePicture = () => {
  const QueryClient = useQueryClient();
  return useMutation<AxiosResponse, any, any>(
    async (payload) => {
      return await apiClient.post(
        `users/${payload.id}/avatar`,
        payload.formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },
    {
      onSuccess() {
        QueryClient.invalidateQueries(['currentUser']);
      },
    },
  );
};
