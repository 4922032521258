import { useMutation, useQueryClient } from '@tanstack/react-query';

import apiClient from '../apiClient';

export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation<any, unknown, any>(
    async (payload) => {
      console.log(payload);
      return await apiClient
        .patch(`/tags/${payload.id}`, payload.category, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['GetTags'] });
      },
    },
  );
};
