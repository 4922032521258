import { userRes } from '@/types/user';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
const initialState = {
  username: '',
  id: '',
  nickname: '',
  phone: '',
  status: '',
  roleId: '',
  role: '',
  bio: '',
  avatarUrl: '',
  dob: new Date(),
  createdAt: new Date(),
};
export const UserSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setEditUser: (state, action: PayloadAction<userRes>) => {
      state.roleId = action.payload.roleId;
      state.dob = action.payload.dob;
      state.id = action.payload.id;
      state.role = action.payload.role;
      state.bio = action.payload.bio;
      state.status = action.payload.status;
      state.nickname = action.payload.nickname;
      state.phone = action.payload.phone;
      state.username = action.payload.username;
      state.avatarUrl = action.payload.avatarUrl;
    },
  },
});

export default UserSlice.reducer;
export const { setEditUser } = UserSlice.actions;
