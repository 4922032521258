import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CancelToken } from 'axios';

import apiClient from '../apiClient';
import { videosKey } from '@/api/video/key.ts';

type uploadVideoProps = {
  formData: {
    description: string;
    categoryId: string;
    title: string;
    videos?: FileList | null;
  };
  cancelToken: CancelToken;
};

export const useUploadVideos = ({
  setLoaded,
}: {
  setLoaded: (Loaded: number) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation<any, unknown, any>({
    mutationKey: ['uploadVideo'],
    mutationFn: async (payload: uploadVideoProps) => {
      return await apiClient
        .post('/videos', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (e) => {
            if (e.total) {
              setLoaded(Math.round((e.loaded * 100) / e.total));
            }
          },
        })
        .then((res) => res.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        videosKey.getAllVideos('1', '10', '', ''),
      );
    },
  });
};
