import { Link } from 'react-router-dom';
import { Button } from '../common/Button';
import { useGetAllUser } from '@/api/users/useGetAllUser';
import { userRes } from '@/types/user';
//@ts-expect-error
import moment from 'moment';

const UserList = () => {
  const { data: users } = useGetAllUser({ role: 'User', page: 1, pageLimit: 5, country: 'Myanmar' });
  return (
    <div className="mt-2 rounded-2xl border border-[#217FCE] bg-[#E5EFF9] p-5">
      <div className="flex items-center justify-between">
        <p className="text-xl">Recent Registrations</p>

        <Link to="/users?role=User&page=1&pageLimit=5">
          <Button variant="primary" className="rounded-xl bg-black">
            See All
          </Button>
        </Link>
      </div>
      <div className=" mt-3">
        <table className=" w-full">
          <thead>
            <tr>
              <th className=" text-start">ID</th>
              <th className=" text-start">User Name</th>
              <th className=" text-start">Gender</th>
              <th className=" text-start">DOB</th>
              <th className=" text-start">Join Date</th>
              {/* <th className=" text-start">Interests</th> */}
            </tr>
          </thead>
          <tbody>
            {users?.data.map((user: userRes, index: number) => {
              return (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  {/* <td>{user.phone}</td> */}
                  <td>{user.name}</td>
                  <td>{user.gender}</td>
                  <td>{moment(user.dob).format('DD/MM/YYYY')}</td>

                  <td>{moment(user.createdAt).format('DD MM YYYY , h:mm:ss a')}</td>
                  {/* <td>Mathematics,Science,Technology</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserList;
