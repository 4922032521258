import { useMutation, useQueryClient } from '@tanstack/react-query';

import apiClient from '../apiClient';

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  return useMutation<any, unknown, any>(
    async (payload: string) => {
      return await apiClient.delete('/tags/' + payload).then((res) => res.data);
    },
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries({ queryKey: ['GetTags'] });
      },
    },
  );
};
