import { useInfiniteQuery } from '@tanstack/react-query';
import apiClient from '../apiClient';

type Notification = {
  id: string;
  acked: boolean;
  createdAt: Date;
  type: string;
  title: string;
  body: string;
  url: string;
  thumbnailUrl: string;
};

type NotificationResponse = {
  totalCount: number;
  unreadCount: number;
  nextPage: number | null; // Changed to accept null value for last page
  data: Notification[];
};

export const useGetNotifications = (limit: number = 10) => {
  return useInfiniteQuery<NotificationResponse>({
    queryKey: ['GetNotifications'],
    getNextPageParam: (lastPage, pages) => {
      // If there are no more pages, return null
      if (lastPage.nextPage === null) {
        return null;
      }
      // Return the next page number
      return lastPage.nextPage;
    },
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const response = await apiClient.get('/notifications', {
          params: {
            page: pageParam,
            limit,
          },
        });
        return response.data;
      } catch (error) {
        // Handle errors here, you might want to throw or log them
        throw new Error('Failed to fetch notifications');
      }
    },
  });
};
