import { Button } from '../Button';
import CustomDialog from '../CustomDialog';
type ConfirmBoxProps = {
  open: boolean;
  onCloseHandle: () => void;
  onConfirm: (isConfirm: any) => void;
  description: string;
};
const ConfirmBox = ({ open, onConfirm, onCloseHandle, description }: ConfirmBoxProps) => {
  return (
    <CustomDialog open={open} onClose={onCloseHandle}>
      <div className="w-[380px] rounded-lg bg-white py-10">
        <h4 className="text-center text-xl font-bold">{description}</h4>
        <div className=" mt-7 flex items-center justify-center gap-16">
          <Button className="rounded-lg bg-orange-500" onClick={onCloseHandle}>
            Cancel
          </Button>
          <Button className=" rounded-lg bg-blue-500" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </CustomDialog>
  );
};

export default ConfirmBox;
