import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import apiClient from '../apiClient';
import { AuthPayload, AuthRes } from '@/types/auth';
import { useAppDispatch } from '@/store/hook.ts';
import { setAccessToken } from '@/store/slices/auth.slice.ts';

export const useAuthLogin = ({
  onSuccess,
  ...options
}: Omit<UseMutationOptions<AuthRes, unknown, AuthPayload>, 'queryFn'> = {}) => {
  const dispatch = useAppDispatch();
  return useMutation<AuthRes, unknown, AuthPayload>(
    async (payload) => {
      return await apiClient
        .post('/auth/login/admin', payload)
        .then((res) => res.data);
    },
    {
      onSuccess: async (data, variables, context) => {
        await onSuccess?.(data, variables, context);
        localStorage.setItem('access_token', data?.accessToken);
        localStorage.setItem('refresh_token', data?.refreshToken);
        dispatch(setAccessToken(JSON.stringify(data.accessToken)));
      },
      ...options,
    },
  );
};
