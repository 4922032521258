import { useQuery } from '@tanstack/react-query';
import { notiKeys } from './noti.key';
import apiClient from '../apiClient';
import { GetAllNotiResponse } from '@/types/noti';

interface GetAllNotiProps {
  page: number;
  limit: number;
  type?: 'others' | 'in_app_generic_noti';
}
export const useGetAllNotifications = ({
  page = 1,
  limit = 10,
  type = 'others',
}: GetAllNotiProps) => {
  return useQuery<GetAllNotiResponse>({
    queryKey: notiKeys.getAllNoti({ page, limit }),
    queryFn: async () => {
      return await apiClient
        .get(`/notifications?page=${page}&limit=${limit}&type=${type}`)
        .then((res) => res.data);
    },
  });
};
