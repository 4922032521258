import { useGetCurrentUser } from '@/api/users/useGetCurrentUser';
import { Button } from '@/components/common/Button';
import ManageAccount from '@/components/setting/ManageAccount';
import Profile from '@/components/setting/Profile';
import Security from '@/components/setting/Security';
import { cn } from '@/utils';
import useSetUrlQuery from '@/utils/query';
import { Lock, User } from 'lucide-react';
const tabList = [
  {
    name: 'Security',
    icon: <Lock />,
  },
  {
    name: 'Manage Account',

    icon: <User />,
  },
];
const Setting = () => {
  const { setQuery, getQuery } = useSetUrlQuery();
  const currentTab = getQuery('tab');

  const { data } = useGetCurrentUser();
  const switchTab = (tab: string) => {
    switch (tab) {
      case 'Profile':
        return <Profile />;
      case 'Security':
        return <Security />;
      case 'Manage Account':
        return <ManageAccount />;
    }
  };
  return (
    <div className="m-8 flex flex-1 gap-4">
      <div className="flex h-full w-[300px] flex-col gap-4">
        <div className=" h-full rounded-xl">
          <div className=" mb-5 flex  items-start gap-3 rounded-xl bg-white p-3">
            <img

              src={data?.avatarUrl ? data.avatarUrl : '/ql_icon.png'}

              alt="profile image"
              className=" h-[80px] w-[80px] rounded-full"
            />
            <div className=" ">
              <p className="text-lg">{data?.username}</p>
              <p className=" text-sm text-slate-600">{data?.nickname ?? 'unknown'}</p>
              <Button
                size="sm"
                variant="transparent"
                className=" mt-2 rounded-2xl border-blue-400"
                onClick={() => setQuery({ key: 'tab', backToFirstPage: false, value: 'Profile' })}
              >
                Edit Profile
              </Button>
            </div>
          </div>
          <div className="h-[500px] rounded-xl bg-white">
            <p className=" border-b-2 border-slate-300 px-3 py-5 text-xl">Dashboard</p>
            {tabList.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={cn(
                    'flex w-full cursor-pointer items-center gap-3 rounded-none border-none p-3 hover:bg-blue-100',
                    tab.name == currentTab && 'bg-blue-100',
                  )}
                  onClick={() => setQuery({ key: 'tab', value: tab.name, backToFirstPage: false })}
                >
                  {tab.icon}
                  {tab.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex h-full flex-1 rounded-xl bg-white shadow-md">
        {/* {activeTab == 'Security' ? <Security /> : <ManageAccount />} */}
        {switchTab(currentTab)}
      </div>
    </div>
  );
};
export default Setting;
