import { date, object, string } from 'yup';
import { phoneRegex } from './validator';
export default function getDateOfYear(year: number) {
  const milliseconds = new Date().setFullYear(year);
  const date = new Date(milliseconds);
  return date;
}

export const AddAdminValidationSchema = object({
  phoneNumber: string()
    .required('Phone number is required')
    .matches(phoneRegex, 'Phone number is not valid')
    .min(9, 'Invalid phone number')
    .max(11, 'Invalid phone number'),
  name: string().required(),
  username: string().required(),
  dob: date()
    .min(getDateOfYear(1980), 'Age must be under 43 years.')
    .max(getDateOfYear(2005), 'Age must be older than 18 years.')
    .required(),
  password: string().required(),
  countryCode: string().required(),
});
