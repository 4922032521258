import { useSearchParams } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Edit, Trash } from 'lucide-react';
import { useGetAllNewsQuery } from '@/api/news/news.query';
import { motion, AnimatePresence } from 'framer-motion';
import CustomModal from '@/components/common/CustomModal';
import EditNew from '@/components/news/EditNew';
import IconClose from '@/components/common/icons/IconClose';
import DeleteNew from './DeleteNew';

const stripHtmlTags = (html: string): string => {
  return html.replace(/<\/?[^>]+(>|$)/g, '');
};

const paginationVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
};

const rowVariant = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
};

const NewsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page') || '1', 10);
  const limit = parseInt(searchParams.get('limit') || '10', 10);

  const {
    data: news,
    isLoading,
    isError,
  } = useGetAllNewsQuery({ page, limit });

  const handlePageChange = (newPage: number) => {
    setSearchParams({ page: newPage.toString(), limit: limit.toString() });
  };

  const handleLimitChange = (newLimit: number) => {
    setSearchParams({ page: '1', limit: newLimit.toString() });
  };

  const totalPages = news ? news.pagination.pageCount : 1;

  // Updated getPaginationButtons to show a dynamic range of 5 pages with ellipses
  const getPaginationButtons = () => {
    const buttons = [];
    const maxVisibleButtons = 5;

    let startPage = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);

    if (endPage - startPage + 1 < maxVisibleButtons) {
      startPage = Math.max(1, endPage - maxVisibleButtons + 1);
    }

    // Add first page and ellipsis if needed
    if (startPage > 1) {
      buttons.push(
        <motion.button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`h-10 w-10 rounded-full border transition-colors duration-200 ${
            page === 1
              ? 'border-blue-500 bg-blue-500 text-white'
              : 'border-gray-300 bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={paginationVariant}
        >
          1
        </motion.button>,
      );
      if (startPage > 2) {
        buttons.push(
          <span key="start-ellipsis" className="px-2">
            ...
          </span>,
        );
      }
    }

    // Add the visible range of pagination buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <motion.button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`h-10 w-10 rounded-full border transition-colors duration-200 ${
            i === page
              ? 'border-blue-500 bg-blue-500 text-white'
              : 'border-gray-300 bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={paginationVariant}
          whileHover={{ scale: 1.1 }}
        >
          {i}
        </motion.button>,
      );
    }

    // Add last page and ellipsis if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        buttons.push(
          <span key="end-ellipsis" className="px-2">
            ...
          </span>,
        );
      }
      buttons.push(
        <motion.button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`h-10 w-10 rounded-full border transition-colors duration-200 ${
            page === totalPages
              ? 'border-blue-500 bg-blue-500 text-white'
              : 'border-gray-300 bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={paginationVariant}
        >
          {totalPages}
        </motion.button>,
      );
    }

    return buttons;
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError || !news?.data) {
    return <p>Failed to load news. Please try again later.</p>;
  }

  return (
    <section>
      {/* News Table */}
      <div className="overflow-auto rounded-xl">
        <table className="w-full text-left text-sm">
          <thead className="bg-[#97B3CE] text-xs uppercase">
            <tr>
              <th scope="col" className="px-6 py-3">
                No
              </th>
              <th scope="col" className="max-w-[100px] px-6 py-3">
                Content
              </th>
              <th scope="col" className="px-6 py-3">
                Thumbnail
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>
          <motion.tbody>
            <AnimatePresence>
              {news.data.length > 0 ? (
                news.data.map((newsItem, index) => (
                  <motion.tr
                    key={newsItem.id}
                    className="border-t odd:bg-[#F1F3F6] even:bg-[#E5EFF9]"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={rowVariant}
                    layout
                  >
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {(page - 1) * limit + index + 1}
                    </td>
                    <td className="max-w-[200px] px-6 py-4 font-medium">
                      {stripHtmlTags(newsItem.body).length > 100
                        ? `${stripHtmlTags(newsItem.body).slice(0, 100)}...`
                        : stripHtmlTags(newsItem.body)}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {newsItem.imageUrls.length > 0 ? (
                        <img
                          src={newsItem.imageUrls?.[0]}
                          alt="Thumbnail"
                          className="h-10 w-20 rounded object-cover"
                        />
                      ) : (
                        'No Image'
                      )}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      <div className="flex items-center gap-x-2">
                        <CustomModal
                          modalOpenButton={(openModal) => (
                            <button
                              onClick={openModal}
                              className="text-blue-500 hover:underline"
                            >
                              <Edit />
                            </button>
                          )}
                          modalCloseButton={(closeModal) => (
                            <button
                              onClick={closeModal}
                              className="absolute right-4 top-2.5"
                            >
                              <IconClose />
                            </button>
                          )}
                          children={(closeModal) => (
                            <EditNew
                              id={newsItem.id}
                              body={newsItem?.body}
                              imageUrls={newsItem.imageUrls}
                              closeModal={closeModal}
                            />
                          )}
                        />
                        <CustomModal
                          modalOpenButton={(openModal) => (
                            <button
                              onClick={openModal}
                              className="ml-2 text-red-500 hover:underline"
                            >
                              <Trash />
                            </button>
                          )}
                          modalCloseButton={(closeModal) => (
                            <button
                              onClick={closeModal}
                              className="absolute right-4 top-2.5"
                            >
                              <IconClose />
                            </button>
                          )}
                          children={(closeModal) => (
                            <DeleteNew
                              id={newsItem.id}
                              closeModal={closeModal}
                            />
                          )}
                        />
                      </div>
                    </td>
                  </motion.tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="px-6 py-4 text-center">
                    No news found.
                  </td>
                </tr>
              )}
            </AnimatePresence>
          </motion.tbody>
        </table>
      </div>

      {/* Pagination and Rows Per Page Controls */}
      <div className="mt-4 flex items-center justify-between">
        {/* Rows per page */}
        <div className="flex items-center">
          <span className="mr-2">Rows per page:</span>
          <select
            value={limit}
            onChange={(e) => handleLimitChange(parseInt(e.target.value, 10))}
            className="rounded border border-primary px-4 py-2"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>

        {/* Pagination buttons */}
        <motion.div
          className="flex items-center space-x-2"
          initial="hidden"
          animate="visible"
          variants={paginationVariant}
        >
          <motion.button
            onClick={() => handlePageChange(Math.max(page - 1, 1))}
            className="flex items-center space-x-1 rounded-full border bg-gray-100 px-3 py-2 hover:bg-gray-200"
            disabled={page === 1}
            whileHover={{ scale: 1.05 }}
          >
            <ChevronLeft size={16} className="text-gray-500" />
            <span>Prev</span>
          </motion.button>
          {getPaginationButtons()}
          <motion.button
            onClick={() => handlePageChange(Math.min(page + 1, totalPages))}
            className="flex items-center space-x-1 rounded-full border bg-gray-100 px-3 py-2 hover:bg-gray-200"
            disabled={page >= totalPages}
            whileHover={{ scale: 1.05 }}
          >
            <span>Next</span>
            <ChevronRight size={16} className="text-gray-500" />
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default NewsTable;
