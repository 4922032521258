import { useState } from 'react';

export const countries = [
  { name: 'MM', code: '+95' },
  { name: 'TH', code: '+66' },
];
export const FlagDropdown = (props: { register: any }) => {
  const [country, setcountry] = useState('MM');
  return (
    <div className="">
      <select {...props.register('countryCode')} className=" rounded-md border-2 border-primary p-2 outline-none">
        {countries.map((country) => {
          return (
            <option key={country.name} value={country.code}>
              {country.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
