import { useQuery } from '@tanstack/react-query';

import apiClient from '../apiClient';
import { userRes } from '@/types/user';

type Pagination = {
  page: number;
  pageSize: number;
  total: number;
  pageCount: number;
};
export const useGetAllUser = ({
  search,
  role,
  page,
  pageLimit,
  country,
}: any) => {
  return useQuery<any, unknown, { data: userRes[]; pagination: Pagination }>({
    queryKey: [page, pageLimit, search, role, country],
    queryFn: async () => {
      return await apiClient
        .get('/users', {
          params: {
            search,
            role,
            limit: pageLimit,
            page,
            country,
          },
        })
        .then((res) => res.data);
    },
    // {refetchInterval:2000}
  });
};
