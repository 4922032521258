import { Search } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button } from '../common/Button';
import useSetUrlQuery from '@/utils/query';
// import CreateNotification from '../CreateNotification';
import { useState } from 'react';
// import { useGetAllUsrRoles } from '@/api/user-role/useGetUserRoles';

const TopBarOfUser = () => {
  const { handleSubmit, register } = useForm();
  // const { data: user_roles } = useGetAllUsrRoles();
  const user_roles = ['User', 'Admin', 'SuperAdmin'];
  const country_list = ['Myanmar', 'Thailand', 'Malaysia', 'Laos'];
  const { setQuery, getQuery } = useSetUrlQuery();
  const search = getQuery('user');

  const searchUser = (data: any) => {
    setQuery({ key: 'user', value: data.search, backToFirstPage: true });
  };
  return (
    <div className="flex items-center justify-center gap-3">
      <form
        onSubmit={handleSubmit(searchUser)}
        className="flex h-[48px] items-center justify-between overflow-hidden rounded-xl border-2 border-primary bg-white focus:ring-2"
      >
        <input
          type="text"
          defaultValue={search}
          placeholder="search users..."
          autoComplete="off"
          className=" h-full bg-white px-3 outline-none focus:bg-white active:bg-white"
          {...register('search')}
        />
        <button>
          <Search className=" h-full w-full bg-primary p-3  text-white" />
        </button>
      </form>
      {/* <CreateNotification /> */}

      <select
        name=""
        value={getQuery('role')}
        className="rounded-xl border-2 border-blue-500 p-2.5"
        onChange={(e) => setQuery({ key: 'role', value: e.target.value, backToFirstPage: true })}
      >
        {user_roles?.map((role) => {
          return (
            <option value={role} key={role}>
              {role}
            </option>
          );
        })}
      </select>

      <select
        name=""
        value={getQuery('country')}
        className="rounded-xl border-2 border-blue-500 p-2.5"
        onChange={(e) => setQuery({ key: 'country', value: e.target.value, backToFirstPage: true })}
      >
        {country_list?.map((country) => {
          return (
            <option value={country} key={country}>
              {country}
            </option>
          );
        })}
      </select>
      <Link to="/users/add-user">
        <Button variant="transparent" size="lg" className=" rounded-xl border-2">
          Add User
        </Button>
      </Link>
    </div>
  );
};

export default TopBarOfUser;
