function storeInLocal(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

function removeFromLocal(key: string) {
  localStorage.removeItem(key);
}

function getFromLocal(key: string) {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
}

export { storeInLocal, removeFromLocal, getFromLocal };
