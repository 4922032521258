import { useQuery } from '@tanstack/react-query';
import { newsKeys } from './news.key';
import apiClient from '../apiClient';
import { getAllNewsResponse } from '@/types/news';

interface GetAllNewsProps {
  page: number;
  limit: number;
}
export const useGetAllNewsQuery = ({
  page = 1,
  limit = 10,
}: GetAllNewsProps) => {
  return useQuery<getAllNewsResponse>({
    queryKey: newsKeys.getAllNewsWithParam({ page, limit }),
    queryFn: async () => {
      return await apiClient
        .get(`/news-feed/posts?page=${page}&limit=${limit}`)
        .then((res) => res.data);
    },
  });
};
