import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const Search = createSlice({
  name: 'search',
  initialState: '',
  reducers: {
    setPageValue: (state, action: PayloadAction<string>) => {
      return (state = action.payload);
    },
  },
});
export const { setPageValue } = Search.actions;
export default Search.reducer;
