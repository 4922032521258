import AddAdminForm from '@/components/user/add-user-form';

const AddUser = () => {
  return (
    <div>
      <AddAdminForm />
    </div>
  );
};

export default AddUser;
