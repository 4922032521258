import { ReactNode } from 'react';
import { Slide, ToastContainer } from 'react-toastify';

type ToastClass = {
  success: string;
  error: string;
  info: string;
  warning: string;
  default: string;
  dark: string;
};
const ToastProvider = ({ children }: { children: ReactNode }) => {
  const toastClass: ToastClass = {
    success: 'bg-green-500',
    error: 'bg-red-500',
    info: 'bg-sky-500',
    warning: 'bg-orange-400',
    default: 'bg-indigo-500',
    dark: 'bg-white-500 font-gray-300',
  };

  const toastType = toastClass.default;
  return (
    <>
      <ToastContainer
        toastClassName={({ type }) =>
          toastClass[type || toastType] +
          ' relative text-sm flex min-h-10 justify-between overflow-hidden cursor-pointer mb-4 p-2'
        }
        transition={Slide}
        theme="colored"
        hideProgressBar
        autoClose={1500}
      />
      {children}
    </>
  );
};
export default ToastProvider;
