import { Line } from 'react-chartjs-2';

const data = {
  datasets: [
    {
      data: [231, 333, 345, 376, 316],
      label: 'User Interest',
      tension: 0.3,
      borderWidth: 2,
      borderColor: 'rgb(75, 192, 192)',
    },
  ],
  labels: ['Phrasal Verbs', 'Grammar Tips', 'Idioms and Expressions', 'Pronunciation Practice', 'Vocabulary Boost'],
};
const options = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        beginAtZero: true,
        min: 100,
        max: 1000,
        stepSize: 50,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const BarQuickLearn = () => {
  return (
    <div className="h-[450px] w-full rounded-2xl border border-[#217FCE] bg-[#E5EFF9] p-5 px-10">
      <Line data={data} options={options} />
    </div>
  );
};

export default BarQuickLearn;
