// get video report like most viewed most comment most liked
import { useQuery } from '@tanstack/react-query';

import apiClient from '../apiClient';
import { AllVideoRes } from '@/types/videos';

export const useGetVideoReports = (list: string) => {
  return useQuery<{ data: AllVideoRes[] }>(
    ['GetReports'],
    async () => {
      return await apiClient
        .get('/videos/reports', {
          params: {
            list,
          },
        })
        .then((res) => res.data);
    },
    {
      staleTime: 60000,
      cacheTime: 60000,
      refetchInterval: 60000,
    },
  );
};
