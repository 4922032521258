import { baseURL } from '@/api/apiClient';
// import { useGetAllComments } from '@/api/comments/useGetAllComments';
import { BookOpenCheck, Film, FolderOpen, User2, X } from 'lucide-react';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const VideoDetail = () => {
  const video = useLocation().state;
  const videoRef = useRef(null);
  const navigate = useNavigate();
  // const { data, isLoading } = useGetAllComments({ videoId: video.id });
  // console.log(video, isLoading);

  return (
    <div className=" relative flex flex-col items-center justify-center p-5">
      <button onClick={() => navigate(-1)}>
        <X className=" absolute right-3 top-3 text-xl font-bold" />
      </button>
      <div className=" mt-14 flex h-[500px] w-[800px] justify-between rounded-2xl bg-[#D9ECFF] p-8 px-[85px] ">
        <div className=" flex w-[350px] flex-col gap-7 rounded-xl bg-[#ECF6FF] p-8">
          <div className="flex items-start justify-start gap-3">
            <BookOpenCheck />
            <div className="">
              <p className=" text-[18px] font-medium text-black">Name</p>
              <p className=" text-slate-500">{video.title}</p>
            </div>
          </div>
          <div className="flex items-start justify-start gap-3">
            <FolderOpen />
            <div className="">
              <p className=" text-[18px] font-medium text-black">Category</p>
              <p className=" text-slate-500">{video.tags[0]?.name}</p>
            </div>
          </div>
          <div className="flex items-start justify-start gap-3">
            <User2 />
            <div className="">
              <p className=" text-[18px]  font-medium text-black">User</p>
              <p className=" text-slate-500">{video.author.username}</p>
            </div>
          </div>
          <div className="flex items-start justify-start gap-3">
            <Film className=" text-[#0D314F]" />
            <div className="">
              <p className=" text-[18px] font-medium text-black">Video</p>
              <p className=" text-slate-500">{video.description}</p>
            </div>
          </div>
        </div>

        <video
          autoPlay
          controls
          className=" h-full w-[240px] rounded-xl bg-slate-100"
          ref={videoRef}
        >
          <source
            src={
              baseURL +
              `/videos/s?$0=${video?.s3Bucket}&$1=${video?.s3VideoKey}&MMIEType=${video?.mmieType}`
            }
            type={video.mmieType}
          />
        </video>
      </div>
    </div>
  );
};

export default VideoDetail;
