import ChangePhoneForm from './ChangePhoneForm';
import ChangePasswordForm from './ChangePasswordForm';

const Security = () => {
  return (
    <div className=" w-full p-5">
      <div className=" border-b border-slate-300 pb-5">
        <h3 className=" text-xl">Security</h3>
      </div>
      {/* <ChangePhoneForm />
      <hr /> */}
      <ChangePasswordForm />
    </div>
  );
};

export default Security;
