import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
};

export const videoSlice = createSlice({
  name: 'videoSlice',
  initialState,
  reducers: {
    setVideoToDelete: (state, { payload }: PayloadAction<string>) => {
      state.id = payload;
    },
  },
});
export const { setVideoToDelete } = videoSlice.actions;
export default videoSlice.reducer;
