import { CircleArrow } from '@/components/common/IconButtons/IconButtons';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className=" flex flex-col items-center justify-center gap-5 pt-[200px]">
      <Link to={'/home/category'}>
        <div className=" flex h-[50px] w-[600px] items-center justify-between rounded-[15px] border border-slate-600 bg-white px-5">
          <p>categories</p>
          <CircleArrow />
        </div>
      </Link>
      <Link to={'/home/slide?published=true'}>
        <div className=" flex h-[50px] w-[600px] items-center justify-between rounded-[15px] border border-slate-600 bg-white px-5">
          <p>Slides</p>
          <CircleArrow />
        </div>
      </Link>
    </div>
  );
};

export default Home;
