import { mixed, object, string } from 'yup';

export const CreateSlideSchema = object({
  name: string().required('name is required!'),
  link: string(),
  content: string(),
  web_banner: mixed().required('WebBanner is required'),
  mobile_banner: mixed().required('MobileBanner is required'),
});

export const EditSlideSchema = object({
  name: string().required('name is required!'),
  link: string(),
  image: mixed().required('Banner is required'),
});
