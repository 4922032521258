import Editor from '@/components/common/Editor';
import IconSquarePlus from '../common/icons/IconSquarePlus';
import { useState } from 'react';
import { useUpdateNewMutation } from '@/api/news/news.mutation';
import { toast } from 'react-toastify';
import { cn } from '@/utils';
import useUploadImage from '@/hooks/useUploadImage';
import IconClose from '../common/icons/IconClose';

interface Props {
  id: string;
  body: string;
  imageUrls: string[];
  closeModal: () => void;
}
const EditNew = ({ id, body, imageUrls, closeModal }: Props) => {
  const [content, setContent] = useState(body);
  const [imageIds, setImageIds] = useState<string[]>([]);
  const [imagePreview, setImagePreview] = useState<string | null>(
    imageUrls?.[0],
  );
  const { mutate: updateNewMutate, isLoading: isUpdating } =
    useUpdateNewMutation(id);
  const { uploadAndGetImageId } = useUploadImage();

  const handleChooseImage = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      const { imageId } = await uploadAndGetImageId(file);
      if (imageId) {
        setImageIds([imageId]);
      }
    }
  };

  const handleUpdateSave = () => {
    if (!content) return;
    const payload = {
      body: content,
      imageIds,
    };
    updateNewMutate(payload, {
      onSuccess() {
        toast.success('Post edited successfully');
        setImagePreview(null);
        closeModal();
      },
      onError(err) {
        toast.error(
          err.response?.data?.message[0] || err?.message || 'Failed to edit',
        );
      },
    });
    setContent('');
  };

  return (
    <div className="w-full max-w-[500px] overflow-auto rounded-lg bg-white">
      <div className="flex h-[50px] items-center justify-center bg-[#E9F3FB]">
        <h1 className="text-center text-[24px] text-[#424242]">Edit Post</h1>
      </div>
      <div className="flex flex-col space-y-2 px-5 py-5">
        <div className="flex items-center gap-x-4">
          <img
            src="/ql-2.svg"
            width={56}
            height={56}
            className="rounded-full border"
            alt=""
          />
          <p className="text-[16px] font-[600] text-[#424242]">
            ILBC Quick Learn
          </p>
        </div>
        <Editor
          content={content}
          setContent={setContent}
          placeholder="What's on your mind"
        />
        {imagePreview ? (
          <div className="relative">
            <img
              src={imagePreview}
              alt="Preview"
              className="h-[200px] w-full rounded-lg object-cover"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                setImagePreview(null);
              }}
              className="absolute right-0 top-0"
            >
              <IconClose className="h-5 w-5" />
            </button>
          </div>
        ) : (
          <label htmlFor="imgUpload">
            <div className="flex h-[200px] flex-col items-center justify-center space-y-2 rounded-lg bg-[#F4F8FB]">
              <input
                onChange={handleChooseImage}
                type="file"
                id="imgUpload"
                hidden
              />
              <IconSquarePlus />
              <p className="text-[16px] text-[#757575]">Add photos/ videos</p>
            </div>
          </label>
        )}
        <div className="flex justify-between">
          <div className="w-full" />
          <button
            onClick={handleUpdateSave}
            disabled={isUpdating}
            className={cn(
              'w-[120px] rounded-[30px] bg-primary py-2 text-[18px] text-white',
              isUpdating && 'cursor-not-allowed bg-primary/20',
            )}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditNew;
