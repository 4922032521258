import { Link } from 'react-router-dom';

const StatCard = ({ data }: any) => {
  return (
    <Link to={data?.to} className="w-full" state={data?.amount}>
      <div className=" h-[110px] w-full rounded-2xl border border-[#217FCE] bg-[#E5EFF9] p-3 hover:bg-slate-300">
        <div className="mt-2 flex gap-3">
          <span className="">{data.icon}</span>
          <div className="">
            <p className=" text-[16px]">{data.title}</p>
            <p className=" text-[24px] font-semibold">{data.amount}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default StatCard;
