import { useGetAllCategory } from '@/api/category/useGetAllCategory';
import { useGetAllUser } from '@/api/users/useGetAllUser';
import { useGetAllVideos } from '@/api/video/useGetAllVideos';
import QuickLoader from '@/components/common/QuickLoader';
import BarQuickLearn from '@/components/dashboard/BarQuickLearn';
import DoughnutQuickLearn from '@/components/dashboard/Doughnut';
import StatCard from '@/components/dashboard/StatCard';
import UserList from '@/components/dashboard/UserList';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { LayoutGrid, Play, Users, Users2 } from 'lucide-react';

Chart.register(
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
);
const DoughnutChart = () => {
  const { data: tags, isLoading } = useGetAllCategory({ search: '' });
  const { data: users, isLoading: userLoading } = useGetAllUser({
    role: 'User',
    country: 'Myanmar',
  });
  const { data: admins, isLoading: adminLoading } = useGetAllUser({
    role: 'Admin',
    country: 'Myanmar',
  });
  const { data: videos, isLoading: videoLoading } = useGetAllVideos();
  if (isLoading || userLoading || adminLoading || videoLoading)
    return <QuickLoader />;
  const statData = [
    {
      title: 'Total Users',
      icon: (
        <Users2 className="h-9 w-9 rounded-full p-2 text-[#AB63E7] shadow-sm shadow-slate-500" />
      ),
      amount: users?.pagination.total,
      to: 'users/user-countdown',
    },
    {
      title: 'Total Categories',
      icon: (
        <LayoutGrid className="h-9 w-9 rounded-full p-2 text-[#AB63E7] shadow-sm shadow-slate-500" />
      ),
      amount: tags?.data.length,
      to: 'category',
    },
    {
      title: 'Total Clips',
      icon: (
        <Play className="h-9 w-9 rounded-full p-2 text-[#AB63E7] shadow-sm shadow-slate-500" />
      ),
      amount: videos?.pagination?.total,
      to: 'videos?page=1&pageLimit=5',
    },
    {
      title: 'Total Authors',
      icon: (
        <Users className="h-9 w-9 rounded-full p-2 text-[#AB63E7] shadow-sm shadow-slate-500" />
      ),
      amount: admins?.pagination.total,
      to: 'users?role=Admin&page=1&pageLimit=5',
    },
  ];
  return (
    <>
      <div className=" relative h-full w-full px-[80px]">
        <div className=" sticky top-0 flex w-full items-center justify-between gap-2 rounded-xl bg-[#d8dfe6] bg-transparent py-3 backdrop-blur-2xl">
          {statData.map((data, index) => {
            return <StatCard key={index} data={data} />;
          })}
        </div>
        {/* <div className=" h-5 w-full bg-slate-400 backdrop-blur-sm"></div> */}
        <div className="mb-2 flex gap-2">
          <DoughnutQuickLearn />
          <BarQuickLearn />
        </div>
        <UserList />
      </div>
    </>
  );
};

export default DoughnutChart;
