import {
  useFileUploadMutation,
  useGetFileUploadUrlMutation,
} from '@/api/news/news.mutation';
import { toast } from 'react-toastify';

const useUploadImage = () => {
  const { mutateAsync: getFileUrlMutate } = useGetFileUploadUrlMutation();
  const { mutateAsync: fileUploadMutate } = useFileUploadMutation();

  const uploadAndGetImageId = async (file: File) => {
    try {
      const data = await getFileUrlMutate();
      await fileUploadMutate({
        url: data.uploadUrl,
        file,
      });
      return {
        imageId: data?.objectId,
      };
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Failed to upload',
      );
      return { imageId: null };
    }
  };

  return {
    uploadAndGetImageId,
  };
};

export default useUploadImage;
