import { cn } from '@/utils';
import { Edit, Info, Trash } from 'lucide-react';

export const DeleteButton = () => <Trash className="w-6 text-red-600" />;
export const EditButton = ({ disabled }: { disabled?: boolean }) => (
  <Edit className={cn('w-6 text-green-600', disabled && 'text-slate-600')} />
);
export const InfoButton = () => <Info className="w-6 text-yellow-600" />;

export const CircleArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M8 12h8" />
      <path d="m12 16 4-4-4-4" />
    </svg>
  );
};
