import { useMutation, useQueryClient } from '@tanstack/react-query';

import apiClient from '../apiClient';
import { usersKeys } from './key';

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, any>(
    async (payload) => {
      return await apiClient
        .patch('/users/' + payload.id, payload.data)
        .then((res) => res.data)
        .catch((err) => err);
    },
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries(usersKeys.getUserById('id'));
      },
    },
  );
};
