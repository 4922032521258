import { Button } from '@/components/common/Button';
import InputBox from '@/components/common/form/InputBox';
import { useForm } from 'react-hook-form';

import { useLocation, useNavigate } from 'react-router-dom';
import { X } from 'lucide-react';
import { useGetAllCategory } from '@/api/category/useGetAllCategory';
import { useState } from 'react';
import MultiSelect from '@/components/common/form/MultiSelect';

import useSetUrlQuery from '@/utils/query';
import { toast } from 'react-toastify';
import { useUpdateVideos } from '@/api/video/useUpdateVideo';
import { uploadVideoProps } from '@/types/videos';
import FileInputBox from '@/components/common/form/FileInputBox';
import { yupResolver } from '@hookform/resolvers/yup';
import { uploadVideoValidationSchema } from '@/validators/video';
import ThumbnailBox from '@/components/common/form/ThumbnailBox';
import UploadProgress from '@/components/quick-learn/UploadProgress';

const UpdateVideo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const video = location.state;
  const [loaded, setLoaded] = useState<number>(0);
  const { mutate, isLoading } = useUpdateVideos({ setLoaded });
  const [duration, setDuration] = useState<any>(0);
  const [videoFile, setVideoFile] = useState<any>(null);
  const [selectedTags, setSelectedTags] = useState<string>(video?.tags);
  const [thumbnail, setThumbnail] = useState<any>();
  const { getQuery } = useSetUrlQuery();
  const tagName = getQuery('tag') || '';
  const { data: tags } = useGetAllCategory({ tagName });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<uploadVideoProps>({
    defaultValues: {
      title: video.title,
      description: video.description,
      video: undefined,
      duration: video.duration,
    },
    //@ts-expect-error
    resolver: yupResolver(uploadVideoValidationSchema),
  });

  const onSubmitUpload = (data: any) => {
    let formData = new FormData();

    formData.append('title', data.title);
    formData.append('tags', selectedTags);
    thumbnail && formData.append('thumbnail', thumbnail);
    if (videoFile) {
      formData.append('video', videoFile);
      formData.append('duration', duration);
    }
    if (data.description) {
      formData.append('description', data.description);
    }

    mutate(
      { data: formData, id: video.id },
      {
        onError() {
          toast.error('Something wrong!');
        },
        onSuccess() {
          toast.success('upload successfully!');
          navigate(-1);
        },
      },
    );
  };
  return (
    <div className="m-5 flex h-screen flex-col items-center justify-start rounded-md bg-white">
      <button
        onClick={() => navigate(-1)}
        className=" absolute right-7 top-7 p-4"
      >
        <X />
      </button>
      <div className="mt-7 w-full px-8 py-4 text-center text-2xl font-semibold">
        Edit Video
      </div>
      {/* edit form start */}
      <form
        onSubmit={handleSubmit(onSubmitUpload)}
        className="flex w-[500px] flex-col gap-2 p-8"
      >
        <InputBox
          type="text"
          name="title"
          error={errors?.title?.message}
          register={register}
          id="title"
          placeholder="video title"
        />

        <MultiSelect
          error={selectedTags == ''}
          options={tags?.data}
          register={register}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
        />
        <FileInputBox
          setVideo={setVideoFile}
          setDuration={setDuration}
          name="video"
          id="video"
          register={register}
          placeholder="video"
          // error={errors.video?.message}
        />
        <ThumbnailBox
          setThumbnail={setThumbnail}
          name="thumbnail"
          // error={!thumbnail}
          register={register}
          id="thumbnail"
          placeholder="thumbnail"
        />
        <InputBox
          type="text"
          name="description"
          // error={errors.description?.message}
          register={register}
          id="description"
          placeholder="description"
        />

        {/* <textarea
          {...register('description')}
          rows={5}
          className="relative flex resize-none rounded-xl  border-2 border-primary bg-transparent p-2 focus:border-primary"
          placeholder="Enter video description..."
        >
          helo
        </textarea> */}

        <Button
          type="submit"
          className="w-[115px]"
          isLoading={isLoading}
          variant="transparent"
        >
          {isLoading ? 'Uploading' : 'Upload'}
        </Button>
      </form>
      <UploadProgress loaded={loaded} isLoading={isLoading} />

      {/* edit form end */}
    </div>
  );
};

export default UpdateVideo;
