import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '../apiClient';

export const usePublicSlide = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: { id: string }) => {
      return apiClient
        .post(`banners/toggle-published/${payload.id}`)
        .then((res) => res.data);
    },
    // onSuccess() {
    //   queryClient.invalidateQueries({ queryKey: ['GetSlides'], exact: false });
    // },
  });
};
