import { useGetAllUser } from '@/api/users/useGetAllUser';
import { useEffect, useState } from 'react';
import CustomDialog from '@/components/common/CustomDialog.tsx';
import { Link, useNavigate } from 'react-router-dom';
import EditUser from './EditUser';
import { useAppDispatch } from '@/store/hook';
import { setEditUser } from '@/store/slices/user.slice';
import TopBarOfUser from '@/components/quick-learn/TopBarOfUser';
import { EditButton, InfoButton } from '@/components/common/IconButtons/IconButtons';
import useSetUrlQuery from '@/utils/query';
import QuickLoader from '@/components/common/QuickLoader';
import { useGetCurrentUser } from '@/api/users/useGetCurrentUser';
import { cn } from '@/utils';
import { MoreHorizontal } from 'lucide-react';
import PaginateFoot from '@/components/quick-learn/PaginateFoot';
const Users = () => {
  const { getQuery } = useSetUrlQuery();
  const search = getQuery('user');
  const role = getQuery('role');
  const country = getQuery('country');
  const page = getQuery('page');
  const pageLimit = getQuery('pageLimit');
  const { data, isLoading } = useGetAllUser({ search, role, country, page, pageLimit });
  const { data: currentUser } = useGetCurrentUser();
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const { mutate } = useDeleteUser();
  const [userRoleDial, setUserRoleDial] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSelectChange = (userId: string) => {
    const isExitInSelect = selectedUsers.find((id) => id === userId);
    if (!isExitInSelect) {
      //@ts-expect-error
      setSelectedUsers([...selectedUsers, userId]);
    } else setSelectedUsers(selectedUsers.filter((id) => id !== userId));
  };
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      //@ts-expect-error
      setSelectedUsers(data?.map((user) => user.id));
    } else setSelectedUsers([]);
  };
  if (isLoading) {
    return <QuickLoader />;
  }

  return (
    <div className="m-4 rounded-tl-md bg-white  p-4 shadow-md">
      <div className="m-4 mb-5 flex items-center justify-between ">
        <h1 className="text-2xl font-medium text-primary-dark">User Role</h1>
        <TopBarOfUser />
      </div>

      {data?.data.length === 0 ? (
        <p className="mt-10 text-center">No user found</p>
      ) : (
        <div className="relative my-4 h-[60vh] overflow-y-auto shadow-md sm:rounded-lg">
          {selectedUsers.length !== 0 && (
            <div className=" absolute flex w-full justify-between bg-white px-6 py-3">
              <input type="checkbox" name="userIds" onChange={handleSelectAll} />
              <MoreHorizontal />
            </div>
          )}
          <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
            <thead className=" bg-[#97B3CE] text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Selected
                </th>
                <th scope="col" className="px-6 py-3">
                  User Id
                </th>
                <th scope="col" className="px-6 py-3">
                  User name
                </th>
                <th scope="col" className="px-6 py-3">
                  Role
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((user) => {
                return (
                  <tr
                    className={cn(
                      'border-t odd:bg-[#F1F3F6] even:bg-[#E5EFF9]',
                      Boolean(user.id === currentUser?.id) && '!bg-green-200',
                    )}
                    key={user.id}
                  >
                    <td className="px-6 py-4">
                      <input
                        type="checkbox"
                        name="userId"
                        checked={selectedUsers.find((id) => id === user.id)}
                        onChange={() => handleSelectChange(user.id)}
                      />
                    </td>

                    <td className="px-6 py-4">{user.id}</td>
                    <th scope="row" className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white">
                      {user?.name}
                    </th>
                    <td className="px-6 py-4">{user.role}</td>
                    <td className="flex gap-2 px-6 py-4">
                      <button
                        onClick={() => {
                          setUserRoleDial(true);
                          dispatch(setEditUser(user));
                          // setSearchParams(`?editId=${user.id}`);
                        }}
                        // disabled={Boolean(user.id !== currentUser?.id && ['Admin', 'Super Admin'].includes(user.role))}
                      >
                        <EditButton
                          disabled={Boolean(
                            user.id !== currentUser?.id && ['Admin', 'Super Admin'].includes(user.role),
                          )}
                        />
                      </button>

                      <Link className="w-6  text-yellow-500" to={`/users/detail/${user.id}`} state={user}>
                        <InfoButton />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <CustomDialog
        open={userRoleDial}
        onClose={() => {
          setUserRoleDial(false);
        }}
      >
        <div className="flex min-h-screen items-center justify-center ">
          <EditUser setUserRoleDial={setUserRoleDial} />
        </div>
      </CustomDialog>

      <div className="">
        <PaginateFoot pageCount={Number(data?.pagination.pageCount)} total={Number(data?.pagination.total)} />
      </div>
    </div>
  );
};
export default Users;
