import { useSearchParams } from 'react-router-dom';

const useSetUrlQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const setQuery = ({ key, value, backToFirstPage }: { key: string; value: string; backToFirstPage: boolean }) => {
    value.length === 0 ? newSearchParams.delete(key) : newSearchParams.set(key, value);
    backToFirstPage && newSearchParams.set('page', '1');
    setSearchParams(newSearchParams);
  };
  const deleteQuery = ({ key }: { key: string }) => {
    newSearchParams.delete(key);
    setSearchParams(newSearchParams);
  };
  const getQuery = (key: string) => {
    return searchParams.get(key) || '';
  };
  return {
    setQuery,
    deleteQuery,
    getQuery,
  };
};
export default useSetUrlQuery;
