import { useMutation, useQueryClient } from '@tanstack/react-query';

import apiClient from '../apiClient';
import { usersKeys } from './key';

export type AddAdminPayload = {
  name: string;
  username: string;
  password: string;
  countryCode: string;
  phoneNumber: string;
  dob: Date;
};

type AddAdminRes = {
  code: number;
  message: string;
};

type AddAdminError = {
  response: { data: { message: string } };
};
export const useAddAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation<AddAdminRes, AddAdminError, AddAdminPayload>(
    async (payload) => {
      return await apiClient
        .post('/auth/admin/new', payload)
        .then((res) => res.data);
    },
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries(
          usersKeys.getAllUser('', '', '', ''),
        );
      },
    },
  );
};
