import { profileMenu } from '@/data/profile';
import { Menu, Transition } from '@headlessui/react';
import { LogoutIcon } from '@/data/profile';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store/hook.ts';
import { setAccessToken } from '@/store/slices/auth.slice.ts';
import { useGetCurrentUser } from '@/api/users/useGetCurrentUser';

const MenuList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data } = useGetCurrentUser();
  const logoutHandler = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    dispatch(setAccessToken(''));
    navigate('/login');
  };
  return (
    <>
      <div className="">
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button>
            <img
              src={data?.imageUrl ? data?.imageUrl : '/ql_icon.png'}
              alt="profile iamge"
              className=" h-10 w-10 rounded-full"
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {profileMenu.map((menu) => {
                return (
                  <Link to={`/${menu.to}`} key={menu.name}>
                    <Menu.Item as="div" key={menu.name} className=" p-3 hover:bg-slate-200 ">
                      <div className="flex w-full cursor-pointer items-center justify-start gap-2  ">
                        <p className="">{menu.icon}</p> <p>{menu.name}</p>
                      </div>
                    </Menu.Item>
                  </Link>
                );
              })}
              <Menu.Item as="div" className="flex items-center gap-2 p-3 hover:bg-slate-200 " onClick={logoutHandler}>
                <LogoutIcon /> Logout
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};

export default MenuList;
