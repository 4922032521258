import { Button, buttonVariants } from '@/components/common/Button';
import InputBox from '@/components/common/form/InputBox';
import { addCategoryValidationSchema } from '@/validators/category';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { cn } from '@/utils';
import { useCreatCategory } from '@/api/category/useCreatCategory';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

type CreateCategoryProps = {
  setIsDialogOpen: (isOpen: boolean) => void;
  isSub?: boolean;
};
const CreateCategory = ({ setIsDialogOpen, isSub = false }: CreateCategoryProps) => {
  const { mutate, isLoading, error } = useCreatCategory();
  const [thumbnail, setThumbnail] = useState<Blob | null>(null);
  const [imageUrl, setImageUrl] = useState('');
  const { id: parentId } = useParams();
  console.log(parentId);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(addCategoryValidationSchema),
  });
  const addCategory = (data: { name: string }) => {
    // capatalize string
    const tagName = data.name
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    console.log(tagName, thumbnail);

    mutate(
      { name: tagName, thumbnail : isSub ? undefined : thumbnail, parentTagId: parentId },
      {
        onSuccess() {
          setIsDialogOpen(false);
          toast.success('Category is successfully created');
        },
        onError(error: any) {
          toast.error(JSON.stringify(error.response.data.message));
        },
      },
    );
  };

  return (
    <div className=" m-5  rounded-xl bg-white p-5">
      <h3 className=" mb-4 text-lg font-bold">Create New Category</h3>
      <form
        onSubmit={handleSubmit(addCategory)}
        className="flex flex-col items-center justify-center"
      >
        <InputBox
          placeholder="Enter new category"
          type="text"
          id="category"
          name="name"
          register={register}
        />
        <p className="mt-2 text-red-700">{errors.name?.message}</p>
        <div className={cn(isSub ? 'hidden' : 'w-full')}>
          <label
            htmlFor="cat_image"
            className={cn(
              'block h-[200px] relative w-full overflow-hidden rounded-2xl border-2 border-primary text-center',
              !imageUrl && 'py-20',
            )}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="" className=" h-full w-full" />
            ) : (
              <div className="absolute right-[38%] top-[35%] mt-2 text-sm">
                <p>+</p>
                <p>195x170px</p>
              </div>
            )}
          </label>
          <input
            type="file"
            className="hidden"
            id="cat_image"
            onChange={(e) => {
              if (e.target.files) {
                const file = e.target.files[0];
                const url = URL.createObjectURL(file);
                setImageUrl(url);
                setThumbnail(file);
              }
            }}
          />
          <p className="p-1 text-xs text-red-500">
            Image file size must be less than 5MB.
          </p>
          {/* <p className="mt-2 text-red-700">{errors.Image?.message}</p> */}
        </div>
        <Button
          isLoading={isLoading}
          className={cn(
            buttonVariants({ variant: 'default', size: 'sm' }),
            'mt-4 px-6 text-sm',
          )}
        >
          Create
        </Button>
      </form>
    </div>
  );
};

export default CreateCategory;
