import { useForm } from 'react-hook-form';
import Input from '../common/form/Input';
import { Button } from '../common/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddAdminValidationSchema } from '@/lib/add-admin-validation';
import { FlagDropdown } from '../common/form/flag-dropdown';
import { AddAdminPayload, useAddAdmin } from '@/api/users/useAddAdmin';
import { toast } from 'react-toastify';

export default function AddAdminForm() {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ resolver: yupResolver(AddAdminValidationSchema) });
  const { mutate } = useAddAdmin();
  const ConfirmAddUser = (values: AddAdminPayload) => {
    mutate(values, {
      onError(error) {
        toast.error(error?.response.data.message);
      },
      onSuccess() {
        toast.success('Added admin successfully.');
      },
    });
  };
  return (
    <div className="p-10">
      <p>this is add admin form</p>
      <form onSubmit={handleSubmit(ConfirmAddUser)} className="space-y-3">
        <div className="flex w-full space-x-2 ">
          <FlagDropdown register={register} />
          <Input
            register={register}
            error={errors.phoneNumber?.message}
            name="phoneNumber"
            placeholder="Enter your phone"
            type="text"
            classname="w-full"
          />
        </div>
        <Input register={register} error={errors.dob?.message} name="dob" placeholder="Enter your dob" type="date" />
        <Input register={register} error={errors.name?.message} name="name" placeholder="Enter your name" type="text" />
        <Input
          register={register}
          error={errors.username?.message}
          name="username"
          placeholder="Enter your username"
          type="text"
        />
        <Input
          register={register}
          error={errors.password?.message}
          name="password"
          placeholder="Enter your password"
          type="text"
        />
        <Button>Add Admin</Button>
      </form>
    </div>
  );
}
