import { useDeleteNewMutation } from '@/api/news/news.mutation';
import { cn } from '@/utils';
import { Loader2 } from 'lucide-react';
import { toast } from 'react-toastify';

interface Props {
  closeModal: () => void;
  id: string;
}
const DeleteNew = ({ closeModal, id }: Props) => {
  const { mutate: deletePostMutate, isLoading: isDeleting } =
    useDeleteNewMutation();

  const handleDelete = () => {
    deletePostMutate(id, {
      onSuccess: () => {
        closeModal();
        toast.success('Post deleted successfully!');
      },
      onError: (error: any) => {
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            'Failed to delete',
        );
      },
    });
  };
  return (
    <div className="rounded-xl bg-white px-3 py-3">
      <h2 className="text-xl font-bold">Delete Post</h2>
      <p className="mt-2 text-gray-600">
        Are you sure you want to delete this post?
      </p>
      <div className="mt-4 flex justify-end gap-2">
        <button
          onClick={closeModal}
          className="rounded-md bg-gray-500 px-4 py-2 font-bold text-white hover:bg-gray-700"
        >
          Cancel
        </button>
        <button
          onClick={handleDelete}
          disabled={isDeleting}
          className={cn(
            'rounded-md flex items-center gap-x-2 bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700',
            isDeleting && 'opacity-40'
          )}
        >
          {isDeleting && <Loader2 />}
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteNew;
