import { useMutation, useQueryClient } from '@tanstack/react-query';

import apiClient from '../apiClient';
import { videosKey } from '@/api/video/key.ts';

type uploadVideoProps = {
  data: {
    description: string;
    categoryId: string;
    title: string;
  };
  id: string;
};

export const useUpdateVideos = ({
  setLoaded,
}: {
  setLoaded: (loaded: number) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation<any, unknown, any>({
    mutationFn: async (payload: uploadVideoProps) => {
      return await apiClient
        .patch('/videos/' + payload.id, payload.data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (e) => {
            if (e.total) {
              setLoaded(Math.round((e.loaded * 100) / e.total));
            }
          },
        })
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      console.log(variables?.id);

      await queryClient.invalidateQueries(
        videosKey.getAllVideos('1', '10', '', variables?.id),
      );
    },
  });
};
