import { userRes } from '@/types/user';
import { useForm } from 'react-hook-form';
import { useUpdateUser } from '@/api/users/useUpdateUser';
import { toast } from 'react-toastify';

import InputBox from '../common/form/InputBox';
import { Button } from '../common/Button';
import * as moment from 'moment';

type Props = {
  user: userRes | undefined;
  setEditImage: (isEdit: boolean) => void;
  imageUrl: string;
};

const EditProfile = ({ user, imageUrl, setEditImage }: Props) => {
  const { mutate } = useUpdateUser();
  let readableDob = user?.dob.slice(0, user?.dob.indexOf('T'));
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: user?.name,
      username: user?.username,
      bio: user?.bio,
      // dob: moment(user?.dob).format('DD/MM/YYYY'),
      dob: readableDob,
    },
  });

  const updateProfile = (data: any) => {
    mutate(
      { id: user?.id, data },
      {
        onSuccess() {
          toast.success('Update successfully');
        },
      },
    );
  };
  return (
    <form onSubmit={handleSubmit(updateProfile)} className=" mt-5 flex w-[400px] flex-col gap-3">
      <div className=" relative flex h-36 w-36 items-center justify-center overflow-hidden rounded-full border border-slate-300">
        <img src={imageUrl} alt="profile image" className=" h-36 w-36 rounded-full object-cover" />
        <button
          type="button"
          onClick={() => setEditImage(true)}
          className="absolute -bottom-1 w-full bg-slate-100 py-2 backdrop-blur-sm"
        >
          Edit
        </button>
      </div>
      <InputBox placeholder="name" register={register} name="name" type="text" id="name" />
      <InputBox placeholder="username" register={register} name="username" type="text" id="nickname" />
      <InputBox placeholder="bio" register={register} name="bio" type="text" id="bio" />
      <input type="date" name="dob" className="rounded-md border-2 border-blue-500 p-2" defaultValue={readableDob} />
      <div className="flex w-full items-center justify-between gap-3 ">
        <Button variant="transparent" className="w-full gap-2 rounded-xl border-2">
          Save Changes
        </Button>
        <Button
          type="reset"
          variant="transparent"
          className="w-[200px] rounded-xl border-2 border-red-500 text-red-600"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
export default EditProfile;
