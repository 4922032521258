import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, buttonVariants } from '@/components/common/Button';
import { cn } from '@/utils';
import { useGetAllCategory } from '@/api/category/useGetAllCategory';
import { Search, X } from 'lucide-react';
import useSetUrlQuery from '@/utils/query';

const TopBarOfVideo = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const { setQuery, getQuery } = useSetUrlQuery();
  const title = getQuery('title');
  const tag = getQuery('tag');
  const { data: categories } = useGetAllCategory({ search: tag });

  const searchVideo = (data: any) => {
    setQuery({ key: 'title', value: data.search, backToFirstPage: false });
  };
  const handleFilterTag = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let tag = e.target.value;
    setQuery({ key: 'tag', backToFirstPage: true, value: tag });
  };
  return (
    <div className="flex items-center justify-center gap-2">
      <form
        onSubmit={handleSubmit(searchVideo)}
        className="flex h-[40px] items-center justify-between overflow-hidden rounded-lg border border-primary bg-white focus:ring-2"
      >
        <input
          defaultValue={title}
          autoComplete="off"
          type="text"
          placeholder="search videos..."
          className=" h-full bg-white px-3 outline-none focus:bg-white active:bg-white"
          {...register('search')}
        />
        {title !== '' && (
          <button onClick={() => searchVideo({ search: '' })}>
            <X />
          </button>
        )}
        <button>
          <Search className=" h-full w-full bg-primary p-2  text-white" />
        </button>
      </form>
      <select
        className=" rounded-md border border-blue-500 bg-white p-2  outline-none hover:bg-slate-200"
        onChange={(e) => handleFilterTag(e)}
        value={tag}
      >
        <option value="">All</option>
        {categories?.data.map((category) => {
          return (
            <option value={category.name} key={category.id}>
              {category.name}
            </option>
          );
        })}
      </select>

      <Button
        onClick={() => navigate('/videos/create')}
        size="lg"
        className={cn(buttonVariants({ variant: 'transparent' }), 'w-[120px] rounded-md hover:bg-primary/20')}
      >
        Upload
      </Button>
    </div>
  );
};

export default TopBarOfVideo;
