import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '../apiClient';

export const useAckNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: { id: string }) => {
      return await apiClient.post(`notifications/${payload.id}/ack`);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['GetNotifications'],
        exact: false,
      });
    },
  });
};
