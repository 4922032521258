import { useMutation, useQueryClient } from '@tanstack/react-query';

import apiClient from '../apiClient';

export const useDeleteSlide = () => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, any>(
    async (payload: { id: string }) => {
      return await apiClient
        .delete(`/banners/${payload.id}`)
        .then((res) => res.data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['GetSlides'] });
      },
    },
  );
};
