import { cn } from '@/utils';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import MenuItem from '@/layout/MenuItem.tsx';
import { menuItems } from '@/layout/menu-item.tsx';

type SideBarProps = {
  title: string;
  expand: boolean;
  nestedRoutes?: any;
  setExpand: Dispatch<SetStateAction<boolean>>;
};
const SideBar = ({ expand, setExpand, title, nestedRoutes }: SideBarProps) => {
  const itemsRef = useRef(null);
  const [showSub, setShowSub] = useState(false);
  function getMap() {
    if (!itemsRef.current) {
      itemsRef.current = new Map();
    }
    return itemsRef.current;
  }

  const clickHandler = (item: SideBarProps) => {
    const map = getMap();
    if (item.nestedRoutes && expand) {
      // e && e.preventDefault();

      const node = map.get(item?.title);
      const nodes = [...map.values()];
      const unSelectedNodes = nodes.filter((v) => v !== node);

      if (node.style.maxHeight) {
        node.style.maxHeight = null;
        setShowSub(false);
      } else {
        node.style.maxHeight = node.scrollHeight + 'px';
        setShowSub(true);
      }

      unSelectedNodes.forEach((node) => {
        if (node.style.maxHeight) {
          node.style.maxHeight = null;
        }
      });
    } else {
      setExpand(true);
    }
  };
  return (
    <div
      className={cn(
        'mt-10 h-screen  overflow-x-auto px-2 pb-16 pt-2 text-sm  transition-[width] duration-100',
        expand ? 'w-64' : 'w-[70px]',
      )}
    >
      <div className="flex flex-col gap-4">
        {menuItems.map((item) => (
          <MenuItem
            key={item.title}
            ref={(node) => {
              const map = getMap();
              if (node) {
                map.set(item.title, node);
              } else {
                map.delete(item.title);
              }
            }}
            //eslint-disable-next-line
            onClick={clickHandler}
            expand={expand}
            item={item}
            showSub={showSub}
          />
        ))}
      </div>
    </div>
  );
};
export default SideBar;
