import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '../apiClient';

export const useUpdateUserRole = () => {
  const QueryClient = useQueryClient();
  return useMutation<any, any, any>(
    async (payload) => {
      return await apiClient.put(`users/${payload.id}`, payload.data);
    },
    {
      onSuccess() {
        QueryClient.invalidateQueries(['GetAllUsers']);
      },
    },
  );
};
