import TagSlice from './slices/tags.slice';
import Search from './slices/search.slice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import Auth from './slices/auth.slice.ts';
import videoSlice from './slices/video.slice.ts';
import UserSlice from './slices/user.slice.ts';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import BannerSlice from './slices/slide.slice.ts';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['authSlice'],
};

export const rootReducers = combineReducers({
  authSlice: Auth,
  videoSlice: videoSlice,
  searchSlice: Search,
  tagSlice: TagSlice,
  UserSlice,
  BannerSlice,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducers);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [thunk],
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
