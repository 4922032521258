import JoditEditor from 'jodit-react';
import { useMemo, useRef } from 'react';

interface EditorProps {
  placeholder?: string;
  content?: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
}
export default function Editor({
  placeholder = '',
  content = '',
  setContent,
}: EditorProps) {
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: placeholder || 'Start typings...',
      toolbar: true, // Show toolbar
      toolbarSticky: false, // Disable sticky toolbar
      toolbarButtonSize: 'small' as 'small',
      minHeight: '100',
      buttons: [
        'bold', // Bold text
        'italic', // Italic text
        'ol', // Unordered list
        'left', // Justify left
        'center', // Justify center
      ],
      toolbarAdaptive: false, // Keep it simple
      statusbar: false,
      addNewLine: false,
    }),
    [placeholder],
  );
  return (
    <JoditEditor
      className="myeditor-container"
      ref={editor}
      value={content}
      config={config}
      onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => console.log(newContent)}
    />
  );
}
