import { SVGProps } from 'react';

const IconSquarePlus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="39"
      fill="none"
      viewBox="0 0 38 39"
      {...props}
    >
      <path
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.375"
        d="M12.666 19.5h12.667M19 25.833V13.167M14.25 35.333h9.5c7.916 0 11.083-3.166 11.083-11.083v-9.5c0-7.917-3.167-11.084-11.084-11.084h-9.5c-7.916 0-11.083 3.167-11.083 11.084v9.5c0 7.916 3.167 11.083 11.083 11.083z"
      ></path>
    </svg>
  );
};

export default IconSquarePlus;
