import { useMutation, useQueryClient } from '@tanstack/react-query';

import apiClient from '../apiClient';

export const useCreateSlide = () => {
  const queryClient = useQueryClient();
  return useMutation<any, unknown, any>({
    mutationFn: async (payload) => {
      console.log(payload);

      return await apiClient
        .post('/banners', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data);
    },
    onSuccess: async () => {
      return await queryClient.invalidateQueries({
        queryKey: ['GetSlides'],
        exact: false,
      });
    },
  });
};
