import { useQuery } from '@tanstack/react-query';

import apiClient from '../apiClient';
import { SlideRes } from '@/types/category';
import useSetUrlQuery from '@/utils/query';

export const useGetSlides = () => {
  const { getQuery } = useSetUrlQuery();
  const page = getQuery('page');
  const limit = getQuery('limit');
  const published = getQuery('published');
  const search = getQuery('search');
  return useQuery<SlideRes>(
    ['GetSlides', page, limit, published, search],
    async () => {
      return await apiClient
        .get('/banners', {
          params: {
            page,
            limit,
            published,
            search,
          },
        })
        .then((res) => res.data);
    },
  );
};
