interface Props {
  thumbnailUrl: string;
  body: string;
  title: string;
}
const NotificationInfo = ({ thumbnailUrl, body, title }: Props) => {
  return (
    <article className="rounded-xl bg-white p-4 max-h-[500px] overflow-auto space-y-3">
      {thumbnailUrl && (
        <img
          src={thumbnailUrl}
          alt="noti_thumbnail"
          className="w-full rounded-md"
        />
      )}
      <h1 className="text-xl font-semibold">{title}</h1>
      <p className="text-md">{body}</p>
    </article>
  );
};

export default NotificationInfo;
