import { Suspense, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import SideBar from './SideBar';
import CustomDrawer from '@/components/common/CustomDrawer.tsx';
import Header from '@/layout/Header.tsx';
import { useGetCurrentUser } from '@/api/users/useGetCurrentUser';

const AppLayout = () => {
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(true);
  const navigate = useNavigate();
  const { data } = useGetCurrentUser();

  const handleClose = () => {
    setOpen(false);
  };
  const isTablet = false;
  return (
    <div className=" box-border flex h-screen flex-col bg-white">
      <Header expand={expand} setExpand={setExpand} />
      <div className="flex flex-1 overflow-hidden">
        <div className="flex overflow-hidden">
          {isTablet ? (
            <CustomDrawer open={open} onClose={handleClose} anchor="left">
              <SideBar expand={expand} setExpand={setExpand} title={''} />
            </CustomDrawer>
          ) : (
            <SideBar title="" expand={expand} setExpand={setExpand} />
          )}
        </div>
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden rounded-tl-[20px] bg-main-200">
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
