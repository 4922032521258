import { twMerge } from 'tailwind-merge';
import { ClassValue, clsx } from 'clsx';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const truncateText = (text: string, limit: number) => {
  const truncateText = text.slice(0, limit);
  if (text.length > limit) return truncateText + ' ...';
  return truncateText;
};
