import { CircleArrow } from '@/components/common/IconButtons/IconButtons';
import { Link } from 'react-router-dom';

const NewsPage = () => {
  return (
    <div className=" flex flex-col items-center justify-center gap-5 pt-[200px]">
      <Link to={'/news/create-news'}>
        <div className=" flex h-[50px] w-[600px] items-center justify-between rounded-[15px] border border-slate-600 bg-white px-5">
          <p>News</p>
          <CircleArrow />
        </div>
      </Link>
      <Link to={'/news/push-noti'}>
        <div className=" flex h-[50px] w-[600px] items-center justify-between rounded-[15px] border border-slate-600 bg-white px-5">
          <p>Push Notification</p>
          <CircleArrow />
        </div>
      </Link>
    </div>
  );
};

export default NewsPage;
