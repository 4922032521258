import { cn } from '@/utils';

type InputProps = {
  type: string;
  register: any;
  name: string;
  placeholder?: string;
  defaultValue?: string;
  error?: string;
  classname?: string;
};

const Input = (props: InputProps) => {
  const { register, name, placeholder, defaultValue, error, type, classname } = props;
  return (
    <div className="w-full">
      <input
        type={type}
        className={cn(' w-full rounded-md border-2 border-primary p-2 outline-none', classname)}
        {...register(name)}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      <p className="text-red-500">{error}</p>
    </div>
  );
};

export default Input;
